import * as React from "react";
import { Modal, Spin, notification, Button, Select, Input } from "antd";
import { Form, Formik } from "formik";
import { addCoinToDistributor } from "../../Graphs/distributor/addCoinToDistributor";

export class AddCoinInDistributor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            isLoading: false, // Fixme this should be true
            closable: true,
        };
    }

    async componentDidMount() {
        if (this.props.refx) {
            this.props.refx(this);
        }
    }

    handleSubmit = async (value) => {
        let body = {
            distributor_id: this.props.SelectedData.id,
            price: value.coin
        }

        let response = await addCoinToDistributor(this.props.authToken, body);
        if (response.status == 200) {
            notification["success"]({
                message: 'DISTRIBUTOR',
            });
            this.setState = ({
                visible: false,
            })
            this.props.onClose();
        }
    };


    handleOk = (e) => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = (e) => {
        this.hide();
    };

    show() {
        this.setState({
            visible: true,
        });
    }

    hide() {
        this.setState({
            visible: false,
        });
        this.props.onClose();
    }

    render() {
        return (
            // <StyleBox>
            <Modal
                width="600px"
                closable={this.state.closable}
                open={this.state.visible}
                title="Add balance in distributor account"
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={null}
                destroyOnClose={true}
            >
                {this.state.isLoading ? (
                    <div style={{ justifyContent: "center" }}>
                        <Spin />
                    </div>
                ) : (
                    <Formik
                        initialValues={{
                            coin: '',
                        }}
                        onSubmit={this.handleSubmit}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldTouched,
                            setFieldValue
                        }) => {
                            return (

                                <Form>
                                    {
                                        <>
                                            <div className="formik-field_wrap" >
                                                <div className="formik-field-wrap">
                                                    Add Coin
                                                    <Input
                                                        id="coin"
                                                        placeholder="Add Coin"
                                                        value={values.coin}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />

                                                </div>

                                            </div>
                                            <Button
                                                style={{ marginTop: "19px", background: "#5C6AC4", color: "#fff" }}
                                                onClick={handleSubmit}
                                            >
                                                Save
                                            </Button>
                                        </>

                                    }
                                </Form>
                            );
                        }}
                    </Formik>
                )}
            </Modal>
        );
    }
}
