import { Button, Input } from "antd";
import { Form, Formik } from "formik";
import React, { Component } from "react";
import { errorMessages } from "../../utils/validationMessages";
import {
  emailValidation,
  fnameValidation,
  lnameValidation,
  mobileLengthValidation,
  mobileValidation,
  passwordValidation,
} from "../../utils/validations";
import ModalWrap from "../common/modal";

export default class AddSalesModal extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      data: [],
      visible: false,
      errors: {},
    };
  }

  validateForm = (values) => {
    const errors = {};

    if (!values.first_name) {
      errors.first_name = errorMessages.first_name_required;
    } else if(fnameValidation(values.first_name)) {
      errors.first_name = errorMessages.fname_capital;
    }

    if (!values.last_name) {
      errors.last_name = errorMessages.last_name_required;
    } else if(lnameValidation(values.last_name)) {
      errors.last_name = errorMessages.lname_capital;
    }

    if (!values.arabic_name) {
      errors.arabic_name = errorMessages.arabic_name_required;
    }

    if (!this.props.data) {
      if (!values.email) {
        errors.email = errorMessages.email_required;
      } else if (emailValidation(values.email)) {
        errors.email = errorMessages.email_validation;
      }

      if (!values.password) {
        errors.password = errorMessages.password_required;
      } else if (passwordValidation(values.password)) {
        errors.password = errorMessages.password_validation;
      }
    }

    if (!values.phone) {
      errors.phone = errorMessages.mobile_required;
    } else if (mobileLengthValidation(values.phone)) {
      errors.phone = errorMessages.mobile_length_validation;
    } else if (mobileValidation(values.phone)) {
      errors.phone = errorMessages.mobile_validation;
    }

    return errors;
  };

  handleSubmit(values) {
    this.props.data
      ? this.props.updateSalesUser(values, this.props.data.id)
      : this.props.createSalesUser(values);
  }
  render() {
    return (
      <React.Fragment>
        <ModalWrap
          visible={this.props.visible}
          closable={this.props.onClose}
          title="Salesman"
          onOk={this.props.handleOk}
          onCancel={this.props.handleCancel}
          isLoading={this.props.isLoading}
        >
          <Formik
            initialValues={
              this.props.data
                ? {
                    first_name: this.props.data.first_name,
                    last_name: this.props.data.last_name,
                    arabic_name: this.props.data.arabic_name,
                    email: this.props.data.email,
                    phone: this.props.data.phone,
                  }
                : {
                    first_name: "",
                    last_name: "",
                    arabic_name: "",
                    email: "",
                    phone: "",
                    password: "",
                  }
            }
            validate={this.validateForm}
            onSubmit={this.handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => {
              return (
                <Form>
                  <div className="formik-field_wrap">
                    <div className="formik-field-left">
                      First Name
                      <Input
                        id="first_name"
                        placeholder="First Name"
                        value={values.first_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.first_name && touched.first_name ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {errors.first_name}
                        </p>
                      ) : null}
                    </div>
                    <div className="formik-field-right">
                      Last Name
                      <Input
                        id="last_name"
                        placeholder="Last Name"
                        value={values.last_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.last_name && touched.last_name ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {errors.last_name}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div className="formik-field_wrap">
                    <div className="formik-field-left">
                      Arabic Name
                      <Input
                        id="arabic_name"
                        placeholder="Arabic Name"
                        value={values.arabic_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.arabic_name && touched.arabic_name ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {errors.arabic_name}
                        </p>
                      ) : null}
                    </div>
                    <div className="formik-field-right">
                      Phone
                      <Input
                        id="phone"
                        placeholder="Phone"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.phone && touched.phone ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {errors.phone}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="formik-field_wrap">
                    <div className="formik-field-left">
                      Email
                      <Input
                        id="email"
                        placeholder="Email"
                        value={values.email}
                        disabled={this.props.data?.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.email && touched.email ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {errors.email}
                        </p>
                      ) : null}
                      {this.state.errors.email ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {this.state.errors.email}
                        </p>
                      ) : null}
                    </div>
                    {!this.props.data && (
                      <div className="formik-field-right">
                        Password
                        <Input
                          id="password"
                          type="password"
                          placeholder="Password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.password && touched.password ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.password}
                          </p>
                        ) : null}
                      </div>
                    )}
                  </div>

                  <Button
                    style={{
                      marginTop: "19px",
                      background: "#5C6AC4",
                      color: "#fff",
                    }}
                    onClick={handleSubmit}
                  >
                    {this.props.data ? "Update" : "Save"}
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </ModalWrap>
      </React.Fragment>
    );
  }
}
