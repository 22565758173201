import React, { Fragment, Component } from "react";
import Title from "./Title";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAllCard } from "../../Graphs/Card/listCard";
import { getAllCompanyCard } from "../../Graphs/Card/listCompanyCard";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Spin, notification } from "antd";
import { addCard } from "../../Graphs/Card/addCard";
import CardRecordsTable from "./CardRecordsTable";
import AddCardModal from "./AddCardModal";
import { alertError } from "../../utils/alert";
class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardList: [],
      cardTotal: 0,
      isLoading: false,
      primaryColor: "#4466f2",
    };
  }

  async componentDidMount() {
    try {
      const { setRouteName, setSearchText } = this.props;
      let editPrimaryColor = await localStorage.getItem("primary_color");

      await setRouteName("CARD");
      await setSearchText("");

      this.setState({
        isLoading: true,
        primaryColor: editPrimaryColor,
      });

      await this.listCardData();
    } catch (e) {
      alertError("CARD", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  listCardData = async () => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListCardData,
        setListCardDataTotal,
        setRouteName,
        setSearchText,
        loginUserData,
      } = this.props;

      this.setState({
        isLoading: true,
      });

      let cardData;

      if (loginUserData && loginUserData.role === "Company") {
        cardData = await getAllCompanyCard(authToken, 0, 10, loginUserData.id);
      } else {
        cardData = await getAllCard(authToken, 0, 10);
      }

      if (cardData.status == 200) {
        //set user data
        await setListCardData(cardData.data);
        await setListCardDataTotal(cardData.total);

        this.setState({
          cardList: cardData.data,
          cardTotal: cardData.total,
        });
      } else if (cardData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw cardData && cardData.message ? cardData.message : "Error";
      }
    } catch (e) {
      alertError("Card", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  generateCsv = async () => {
    try {
      const { authToken } = this.props;
      this.setState(
        {
          isLoading: true,
        },
        () => {
          getAllCard(authToken, 0, 1000000)
            .then((res) => {
              let csvArray = [];

              csvArray.push([
                "Name",
                "description",
                "Type Name",
                "Category name",
                "Color",
                "Amount",
                "Discount Amount",
                "Validity",
                "Terms and Condition",
                "start Date",
                "end Date",
                "Is Active",
                "Is Paid",
                "Is Buy",
              ]);

              res.data.map((data) => {
                csvArray.push([
                  data.name ? data.name : "NA",
                  data.description ? data.description : "NA",
                  data.type ? data.type.name : "NA",
                  data.category ? data.category.name : "NA",
                  data.color ? data.color : "NA",
                  data.amount ? String(data.amount) : "NA",
                  data.discount_amount ? String(data.discount_amount) : "NA",
                  data.validity ? data.validity : "NA",
                  data.terms_and_condition ? data.terms_and_condition : "NA",
                  data.start_date ? data.start_date : "NA",
                  data.end_date ? data.end_date : "NA",
                  data.is_active ? String(data.is_active) : "NA",
                  data.is_paid ? String(data.is_paid) : "NA",
                  data.is_buy ? String(data.is_buy) : "NA",
                ]);
              });

              const rows = csvArray;
              let csvName = "AllCards.csv";
              let csvContent = "data:text/csv;charset=utf-8,";

              rows.forEach(function (rowArray) {
                let row = rowArray.join(",");
                csvContent += row + "\r\n";
              });

              var encodedUri = encodeURI(csvContent);
              // // // // window.open(encodedUri);
              var link = document.createElement("a");
              link.setAttribute("href", encodedUri);
              link.setAttribute("download", csvName);
              document.body.appendChild(link); // Required for FF

              link.click();

              this.setState({
                isLoading: false,
              });
            })
            .catch((err) => {
              console.log("ERROR", err);
              throw err;
            });
        }
      );
    } catch (e) {
      console.log("error in generateCsv function", e);
    }
  };

  addSubmit = async (values) => {
    try {
      const { authToken, history, setLoginFlag, setUserData, setUserToken } =
        this.props;
      this.setState({
        isLoading: true,
      });

      let addCardData = await addCard(authToken, values);

      if (addCardData.status == 200) {
        notification["success"]({
          message: "Add Card",
          description: "Added successfully",
        });
        await this.listCardData();
      } else if (addCardData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw addCardData && addCardData.message
          ? addCardData.message
          : "Error";
      }
    } catch (e) {
      alertError("CARD", e);
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const { primaryColor, cardList, isLoading, cardTotal } = this.state;
    const { authToken, loginUserData } = this.props;
    const accessPermission =
      (loginUserData.access_permission &&
        JSON.parse(loginUserData.access_permission)) ||
      false;

    return loginUserData.role !== "Admin" &&
      loginUserData.role !== "Company" &&
      (!accessPermission ||
        !accessPermission["card"] ||
        accessPermission["card"].view == false) ? (
      <Fragment>
        <h5 style={{ marginTop: "30%", marginLeft: "30%" }}>
          You are not authorized for view this page.
        </h5>
      </Fragment>
    ) : (
      <Fragment>
        <Title parent="Card" title="card" />
        <div
          style={{
            marginTop: "20px",
            display: "inline-block",
            // width: "100%",
            marginBottom: "20px",
            paddingLeft: "14px",
            paddingRight: "55px",
          }}
        >
          <div style={{ float: "right", cursor: "pointer" }}>
            {loginUserData.role == "Admin" ||
            (accessPermission &&
              accessPermission["card"] &&
              accessPermission["card"].edit) ? (
              <AddCardModal
                primaryColor={primaryColor}
                onSubmit={this.addSubmit}
                authToken={authToken}
              />
            ) : null}
          </div>
        </div>
        <div className="container-fluid">
          {isLoading ? (
            <div style={{ marginLeft: "20px" }}>
              <Spin
                size="large"
                style={{ marginLeft: "480px", marginTop: "130px" }}
              />
            </div>
          ) : (
            <div>
              <CardRecordsTable
                data={cardList}
                primaryColor={primaryColor}
                cardTotal={cardTotal}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListCardData: (listCardData) => {
      dispatch({
        type: "SET_LIST_CARD_DATA",
        listCardData: listCardData,
      });
    },
    setListCardDataTotal: (listCardDataTotal) => {
      dispatch({
        type: "SET_LIST_CARD_DATA_TOTAL",
        listCardDataTotal: listCardDataTotal,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
    setSearchText: (searchText) => {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: searchText,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Card));
