import * as React from "react";
import RedeemCodeData from "./RedeemCodeData";
import ModalWrap from "../common/modal";

export class ViewRedeemModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: [],
      recordDetails: {},
      isLoading: false, // Fixme this should be true
      closable: true,
    };
  }

  componentDidMount() {}

  render() {
    return (
      <ModalWrap
        visible={this.props.isVisible}
        closable={this.props.onClose}
        title="Redeem Data"
        onOk={this.props.onClose}
        onCancel={this.props.onClose}
        isLoading={this.state.isLoading}
        width="1000px"
      >
        <RedeemCodeData
          data={this.props.uploadRedeemData}
          authToken={this.props.authToken}
          otpToken={this.props.otpToken}
          expireToken={this.props.expireToken}
          onClose={this.props.onClose}
        />
      </ModalWrap>
    );
  }
}
