import { callApiToServer } from '../callApi';

export async function listUserBalanceReport(
  authtoken,
  startDate,
  endDate
) {
  try{
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization" : "Berear " + authtoken
    };
      
    let body = JSON.stringify({
        startDate : startDate,
        endDate : endDate
    });
  
    let endUrl = "v1/auth/list-user-balance-report";
    console.log("endUrl printed here", endUrl);
  
    let responseData = await callApiToServer(body, header, "POST", endUrl);
      
    return responseData;
      
  }catch(err) {
    throw err;
  }
}
