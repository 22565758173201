import { Button, Popconfirm } from "antd";
import * as React from "react";
import { Layout, Input, Select, Spin } from "antd";
import { Formik, Field } from "formik";
import { connect } from "react-redux";
import { WEBURL, color } from "../../constant/comman";
import { PictureOutlined, FileImageOutlined } from "@ant-design/icons";
import { AddCardIcon } from "./AddCardIcon";
import * as la from "lodash";
import { getAllCategory } from "../../Graphs/Master/Category/listCategory";
import { getAllType } from "../../Graphs/Master/Type/listType";
import { getAllCountry } from "../../Graphs/Master/Country/listCountry";
import { getAllCompany } from "../../Graphs/Company/listCompany";
import { getAllCoin } from "../../Graphs/Master/Coin/listCoin";
import { withRouter } from "react-router";
import ModalWrap from "../common/modal";
import {
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import LikeCardModal from "./LikeCardModal";
import { StyleSelect } from "../../assets/css/style";
import MintCardModal from "./MintCardModel";

const { Content } = Layout;
const Option = Select.Option;

var amenities = [];

class AddCardModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDetailsModalclose = this.handleDetailsModalclose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      allSubCategoryType: [],
      locationList: [],
      recordSelectedtoView: null,
      data: [],
      view: false,
      viewLogo: false,
      viewImage: false,
      index: null,
      isLoading: false,
      popup: false,
      checked: false,
      item: null,
      filters: null,
      visible: false,
      inputVisible: false,
      inputValue: "",
      time: "",
      closeTime: "",
      closeTimeString: "",
      timeString: "",
      errors: {
        uploadImage: "",
        uploadLogo: "",
        startDate: "",
        endDate: "",
      },
      roleType: "1",
      startDate: "",
      endDate: "",
      isPaid: false,
      isShouldComponentUpdate: false,
      countryDataObject: {},
      coinDataObject: {},
      countryData: null,

      temp: [],
      anisCardData: null,
      openAnisModal: false,
      openLikeModal: false,
      openMintModal: false,
      likeCardData: null,
      mintCardData: null,

      initialValues: {
        // thirdPartyCategory: undefined,
        // thirdPartySubCategory: undefined,
        name: "",
        description: "",
        color: undefined,
        category: undefined,
        subcategory: undefined,
        company_id: undefined,
        validity: undefined,
        amount: "",
        cost_price: "",
        store_sell_price: "",
        redeem_code: null,
        terms_and_condition: "",
        is_paid: false,
        coin: "",
        // commission: "",
        allowed_coins: undefined,
        allowed_countries: undefined,
        instructions: "",
        arabicName: "",
      },
    };
  }

  input;
  allColorType = [];
  selectedColorType = "";
  allCategoryType = [];
  selectedCategoryType = "";
  selectedSubCategoryType = "";
  allCompanyType = [];
  selectedCompanyType = "";
  alltypeType = [];
  selectedTypeType = "";
  selctedColorKey = "";
  src = "";
  logoSrc = "";
  imageSrc = "";
  viewModalRef;
  logoModalRef;
  imageViewModalRef;
  allValidityType = [
    <Option key={"3"} value={"3"}>
      3 Months
    </Option>,
    <Option key={"6"} value={"6"}>
      6 Months
    </Option>,
    <Option key={"12"} value={"12"}>
      12 Months
    </Option>,
  ];
  selectedValidityType = "";
  allCoinsList = [];
  selectedCountry = [];
  selectedCoin = "";

  async componentDidMount() {
    try {
      const { authToken } = this.props;
      const { countryDataObject, coinDataObject } = this.state;
      let dummyCountryDataObject = { ...countryDataObject };

      this.setState({
        isLoading: true,
      });
      let categoryData = await getAllCategory(authToken, 0, 100000000);
      let typeData = await getAllType(authToken, 0, 100000000);
      let countryData = await getAllCountry(authToken, 0, 100000000);
      let companyData = await getAllCompany(authToken, 0, 100000000);

      if (categoryData.status == 200) {
        this.setState({
          temp: categoryData.data,
          isLoading: false,
        });
      }

      countryData &&
        countryData.data &&
        countryData.data.length > 0 &&
        la.map(countryData.data, (subData, subIndex) => {
          if (subData.is_gcc) {
            dummyCountryDataObject[subData.id] = {
              amount: 0,
              discount_amount: 0,
            };
          }
        });

      la.map(color, (data, index) => {
        this.allColorType.push(
          <Option key={index} value={data.value}>
            <div style={{ display: "inline-block", width: "100%" }}>
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  backgroundColor: data.value,
                  float: "left",
                  borderRadius: "5px",
                }}
              ></div>
              <span
                style={{
                  float: "left",
                  margin: "0px",
                  padding: "0px",
                  marginLeft: "10px",
                  marginTop: "5px",
                  lineHeight: "0px",
                }}
              >
                {data.name}
              </span>
            </div>
          </Option>
        );
      });

      categoryData &&
        categoryData.data &&
        categoryData.data.length > 0 &&
        la.map(categoryData.data, (subData, subIndex) => {
          this.allCategoryType.push(
            <Option key={subData.id} value={subData.id}>
              {subData.name}
            </Option>
          );
        });

      companyData &&
        companyData.data &&
        companyData.data.length > 0 &&
        la.map(companyData.data, (subData, subIndex) => {
          this.allCompanyType.push(
            <Option key={subData.id} value={subData.id}>
              {subData.name}
            </Option>
          );
        });

      typeData &&
        typeData.data &&
        typeData.data.length > 0 &&
        la.map(typeData.data, (subData, subIndex) => {
          this.alltypeType.push(
            <Option key={subData.id} value={subData.id}>
              {subData.name}
            </Option>
          );
        });

      await this.getCoinsList();

      this.setState(
        {
          isLoading: false,
          countryData: countryData.data,
          countryDataObject: dummyCountryDataObject,
        },
        () => {
          console.log(
            "!!!!!!!!!!!!!!!!!coinDataObject:",
            this.state.coinDataObject
          );
        }
      );
    } catch (e) {
      console.log("!!!!!!error in did mount", e);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { isShouldComponentUpdate } = nextState;
    if (!isShouldComponentUpdate) {
      this.selectedColorType = "";
      this.selectedCategoryType = "";
      this.selectedSubCategoryType = "";
      this.selectedValidityType = "";
      this.selectedTypeType = "";
      this.src = undefined;
      this.logoSrc = undefined;
      this.imageSrc = undefined;
    }

    return true;
  }

  getCoinsList = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      let coinData = await getAllCoin(this.props.authToken, 0, 100000);
      if (coinData.status == 200) {
        this.allCoinsList = coinData.data;
      }
    } catch (error) {
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  uploadImage = (name) => {
    if (name != null) {
      this.src = name;
      this.setState({
        view: false,
      });
    } else {
      this.setState({
        view: false,
      });
    }
  };

  uploadLogo = (name) => {
    if (name != null) {
      this.logoSrc = name;
      this.setState({
        viewLogo: false,
      });
    } else {
      this.setState({
        viewLogo: false,
      });
    }
  };

  uploadTypeImage = (name) => {
    if (name != null) {
      this.imageSrc = name;
      this.setState({
        viewImage: false,
      });
    } else {
      this.setState({
        viewImage: false,
      });
    }
  };

  showViewModal(params) {
    this.setState(
      {
        view: true,
      },
      () => {
        this.viewModalRef.show();
      }
    );
  }

  showLogoModal(params) {
    this.setState(
      {
        viewLogo: true,
      },
      () => {
        this.logoModalRef.show();
      }
    );
  }

  showImageViewModal(params) {
    this.setState(
      {
        viewImage: true,
      },
      () => {
        this.imageViewModalRef.show();
      }
    );
  }

  handleSelectValiditytype(value, option) {
    this.selectedValidityType = value;
  }

  handleSelectColortype(value, option) {
    this.selectedColorType = value;
    this.selctedColorKey = option.key;
  }

  handleSelectCategorytype(value, option) {
    this.selectedCategoryType = value;
    let subCategoryData = this.state.temp.find(
      (i) => i.id == value
    ).sub_categories;

    this.setState({
      allSubCategoryType:
        subCategoryData && subCategoryData.length ? subCategoryData : [],
    });
  }

  handleSelectSubCategorytype(value, option) {
    this.selectedSubCategoryType = value;
  }

  handleSelectCompanytype(value, option) {
    this.selectedCompanyType = value;
  }

  handleSelectTypetype(value, option) {
    this.selectedTypeType = value;
  }

  handleSelectCountry(value, option) {
    this.selectedCountry = value;
  }

  handleSelectCoin(value, option) {
    this.selectedCoin = value;
  }

  onChange(time, timeString) {
    console.log(time, timeString);
  }

  handleChange(value) {
    console.log(`selected ${value}`);
    amenities = value;
  }

  validateForm = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = "Name is required";
    }
    if (!values.description) {
      errors.description = "Description is required";
    }

    if (!this.state.anisCardData) {
      if (!(values.amount > 0)) {
        errors.amount = "Price is required";
      }
    }

    if (this.selectedColorType === "") {
      errors.color = "Color is required";
    }

    if (
      !Array.isArray(values.allowed_coins) ||
      values.allowed_coins.length == 0
    ) {
      errors.allowed_coins = "Coin is required";
    }
    if (
      !Array.isArray(values.allowed_countries) ||
      values.allowed_countries.length == 0
    ) {
      errors.allowed_countries = "Country is required";
    }

    return errors;
  };

  handleSubmit = (values, action) => {
    const { startDate, endDate, isPaid, anisCardData, likeCardData, mintCardData } =
      this.state;

    if (mintCardData) {
      values.mint_card_ean = mintCardData.mintCardEan?.value;
      values.mint_card_amount = mintCardData.mintCardAmount;
      values.mint_category_id = mintCardData.mintCategoryId;
      values.mint_brand_id = mintCardData.mintBrandId?.value;
    }

    values.start_date = startDate;
    values.end_date = endDate;
    values.color = this.selctedColorKey;
    values.category = this.selectedSubCategoryType
      ? this.selectedSubCategoryType
      : this.selectedCategoryType;
    values.company_id = this.selectedCompanyType;
    values.type = this.selectedTypeType;
    values.validity = this.selectedValidityType;
    values.is_paid = isPaid;

    values.commission = anisCardData && anisCardData.commission;
    values.api_card_id = anisCardData && anisCardData.cardId;

    values.like_card_amount = likeCardData && likeCardData.likeAmount;
    values.like_card_id = likeCardData && likeCardData.cardId;

    if (this.src && this.src === "") {
      values.icon = null;
    } else {
      values.icon = this.src;
    }

    if (this.logoSrc && this.logoSrc === "") {
      values.logo = null;
    } else {
      values.logo = this.logoSrc;
    }

    if (this.imageSrc && this.imageSrc === "") {
      values.image = null;
    } else {
      values.image = this.imageSrc;
    }

    values.allowed_coins = values.allowed_coins.join(",");
    values.allowed_countries = values.allowed_countries.join(",");

    this.props.onSubmit(values);

    this.setState({
      visible: false,
      isShouldComponentUpdate: false,

      likeCardData: null,
      mintCardData: null
    });
  };

  onstartDateChange = (date, dateString) => {
    this.setState({
      startDate: dateString,
    });
  };

  onEndDateChange = (date, dateString) => {
    this.setState({
      endDate: dateString,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
      isShouldComponentUpdate: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      isShouldComponentUpdate: false,
      anisCardData: null,
      likeCardData: null,
      mintCardData: null,
    });
  };

  handleTime(e, s) {
    this.setState({
      time: e,
      timeString: s,
    });
  }

  handleCloseTime(e, s) {
    this.setState({
      closeTime: e,
      closeTimeString: s,
    });
  }

  handleDetailsModalclose(item) {
    this.setState({
      view: false,
      lat: item.lat,
      long: item.lng,
    });
  }

  onPaidChange = (checked) => {
    this.setState({
      isPaid: checked,
    });
  };

  onCountryPriceChanged = (value, field, data) => {
    const { coinDataObject } = this.state;
    let dummyCoinDataObject = { ...coinDataObject };
    dummyCoinDataObject[data.id][field] = value;

    if (field === "amount") {
      dummyCoinDataObject[data.id]["discount_amount"] = value;
    }

    this.setState({
      coinDataObject: dummyCoinDataObject,
    });
  };

  findFilterNames(searchText, data) {
    let rgxp = new RegExp(searchText, "g");
    return data.filter((x) => x.label.match(rgxp));
  }

  render() {
    const { primaryColor } = this.props;
    const { isLoading, countryData, anisCardData, likeCardData, mintCardData } = this.state;
    return (
      <div>
        <div
          onClick={this.showModal}
          style={{
            cursor: "pointer",
            width: "140px",
            backgroundColor: primaryColor,
            color: "white",
            padding: "7px",
            textAlign: "center",
            borderRadius: "50px",
          }}
        >
          Add New Card
        </div>

        <ModalWrap
          visible={this.state.visible}
          closable={this.handleCancel}
          title="Add Card"
          onOk={this.handleCancel}
          onCancel={this.handleCancel}
          isLoading={this.state.isLoading}
        >
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={this.state.initialValues}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return (
                  <div>
                    <div
                      className="formik-field_wrap "
                      style={{
                        border: "1px solid #4fc3f7",
                        textAlign: "left",
                        padding: "8px 11px",
                        backgroundColor: "aliceblue",
                        borderRadius: "5px",
                      }}
                    >
                      <div
                        className="formik-field-left"
                        style={{ marginTop: "5px" }}
                      >
                        {mintCardData
                          ? "Edit / Delete Mint Card"
                          : "Add Mint Card"}
                      </div>
                      <div
                        className="formik-field-right"
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        {mintCardData ? (
                          <>
                            <Button
                              className=" btn-xs ml-5"
                              shape="circle"
                              icon={<EditOutlined />}
                              onClick={() =>
                                this.setState({
                                  openMintModal: true,
                                })
                              }
                            />
                            <Popconfirm
                              onConfirm={() => {
                                this.setState({
                                  mintCardData: null,
                                });
                              }}
                              title="Are you sure you want to delete card?"
                            >
                              <Button
                                className=" btn-xs ml-3"
                                shape="circle"
                                icon={<DeleteOutlined />}
                              />
                            </Popconfirm>
                          </>
                        ) : (
                          <Button
                            className=" btn-xs ml-5"
                            shape="circle"
                            icon={<PlusCircleOutlined />}
                            onClick={() =>
                              this.setState({
                                openMintModal: true,
                              })
                            }
                          />
                        )}
                      </div>
                    </div>


                    <div
                      className="formik-field_wrap "
                      style={{
                        border: "1px solid #4fc3f7",
                        textAlign: "left",
                        padding: "8px 11px",
                        backgroundColor: "aliceblue",
                        borderRadius: "5px",
                      }}
                    >
                      <div
                        className="formik-field-left"
                        style={{ marginTop: "5px" }}
                      >
                        {likeCardData
                          ? "Edit / Delete Like Card"
                          : "Add Like Card"}
                      </div>
                      <div
                        className="formik-field-right"
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        {likeCardData ? (
                          <>
                            <Button
                              className=" btn-xs ml-5"
                              shape="circle"
                              icon={<EditOutlined />}
                              onClick={() =>
                                this.setState({
                                  openLikeModal: true,
                                })
                              }
                            />
                            <Popconfirm
                              onConfirm={() => {
                                this.setState({
                                  likeCardData: null,
                                });
                              }}
                              title="Are you sure you want to delete card?"
                            >
                              <Button
                                className=" btn-xs ml-3"
                                shape="circle"
                                icon={<DeleteOutlined />}
                              />
                            </Popconfirm>
                          </>
                        ) : (
                          <Button
                            className=" btn-xs ml-5"
                            shape="circle"
                            icon={<PlusCircleOutlined />}
                            onClick={() =>
                              this.setState({
                                openLikeModal: true,
                              })
                            }
                          />
                        )}
                      </div>
                    </div>

                    <hr style={{ marginTop: "30px" }} />

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Name
                        <Input
                          id="name"
                          name="name"
                          placeholder="Card Name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className="formik-field-right">
                        Arabic Name
                        <Input
                          id="arabicName"
                          placeholder="Arabic Card Name"
                          value={values.arabicName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Description
                        <Input
                          id="description"
                          placeholder="Card Description"
                          value={values.description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.description && touched.description ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.description}
                          </p>
                        ) : null}
                      </div>
                      <div className="formik-field-right">
                        Card Price
                        <Input
                          id="amount"
                          placeholder="Card Amount"
                          type="number"
                          min={0}
                          value={values.amount}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.amount && touched.amount ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.amount}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Color
                        <Field
                          name="color"
                          render={({ field }) => (
                            <StyleSelect
                              showSearch
                              style={{ width: "100%" }}
                              placeholder="Choose Color"
                              {...field}
                              onChange={(value) => {
                                setFieldValue("color", value);
                              }}
                              onBlur={() => setFieldTouched("color", true)}
                              onSelect={(value, option) =>
                                this.handleSelectColortype(value, option)
                              }
                            >
                              {this.allColorType}
                            </StyleSelect>
                          )}
                        />
                        {errors.color && touched.color ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.color}
                          </p>
                        ) : null}
                      </div>

                      <div className="formik-field-right">
                        Cost Price
                        <Input
                          id="cost_price"
                          placeholder="Cost Price"
                          type="number"
                          min={0}
                          value={values.cost_price}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Category
                        <Field
                          name="category"
                          render={({ field }) => (
                            <StyleSelect
                              showSearch
                              style={{ width: "100%" }}
                              placeholder="Choose Category"
                              {...field}
                              // disabled={true}
                              // disabled={true}
                              onChange={(value) => {
                                setFieldValue("category", value);
                              }}
                              onBlur={() => setFieldTouched("category", true)}
                              onSelect={(value, option) =>
                                this.handleSelectCategorytype(value, option)
                              }
                            >
                              {this.allCategoryType}
                            </StyleSelect>
                          )}
                        />
                        {errors.category && touched.category ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.category}
                          </p>
                        ) : null}
                      </div>

                      <div className="formik-field-right">
                        SubCategory
                        <Field
                          name="subcategory"
                          render={({ field }) => (
                            <StyleSelect
                              showSearch
                              style={{ width: "100%" }}
                              placeholder="Choose SubCategory"
                              {...field}
                              onChange={(value) => {
                                setFieldValue("subcategory", value);
                              }}
                              disabled={
                                this.state.allSubCategoryType.length < 1
                              }
                              onBlur={() =>
                                setFieldTouched("subcategory", true)
                              }
                              onSelect={(value, option) =>
                                this.handleSelectSubCategorytype(value, option)
                              }
                            >
                              {this.state.allSubCategoryType.map((v) => (
                                <Option key={v.id} value={v.id}>
                                  {v.name}
                                </Option>
                              ))}
                            </StyleSelect>
                          )}
                        />
                        {errors.subcategory && touched.subcategory ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.subcategory}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Coin
                        {this.selectedAllowCoins}
                        <Field
                          name="allowed_coins"
                          render={({ field }) => (
                            <StyleSelect
                              mode="multiple"
                              tokenSeparators={[","]}
                              showSearch
                              style={{ width: "100%" }}
                              placeholder="Choose coins"
                              {...field}
                              onChange={(value) => {
                                setFieldValue("allowed_coins", value);
                              }}
                              onBlur={() =>
                                setFieldTouched("allowed_coins", true)
                              }
                              onSelect={(value, option) =>
                                this.handleSelectCountry(value, option)
                              }
                            >
                              {this.allCoinsList &&
                                this.allCoinsList.length &&
                                this.allCoinsList.map((i, index) => {
                                  return (
                                    <option key={index} value={i.id}>
                                      {" "}
                                      {i.name}
                                    </option>
                                  );
                                })}
                            </StyleSelect>
                          )}
                        />
                        {errors.allowed_coins && touched.allowed_coins ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.allowed_coins}
                          </p>
                        ) : null}
                      </div>

                      <div className="formik-field-right">
                        Country
                        <Field
                          name="allowed_countries"
                          render={({ field }) => (
                            <StyleSelect
                              mode="multiple"
                              tokenSeparators={[","]}
                              showSearch
                              style={{ width: "100%" }}
                              placeholder="Choose country"
                              {...field}
                              onChange={(value) => {
                                setFieldValue("allowed_countries", value);
                              }}
                              onBlur={() =>
                                setFieldTouched("allowed_countries", true)
                              }
                            >
                              {countryData &&
                                countryData.length &&
                                countryData.map((i, index) => {
                                  return (
                                    <option key={index} value={i.id}>
                                      {" "}
                                      {i.name}
                                    </option>
                                  );
                                })}
                            </StyleSelect>
                          )}
                        />
                        {errors.allowed_countries &&
                        touched.allowed_countries ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.allowed_countries}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Instructions
                        <Input
                          id="instructions"
                          placeholder="Card Instructions"
                          value={values.instructions}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.instructions && touched.instructions ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.instructions}
                          </p>
                        ) : null}
                      </div>
                      <div className="formik-field-right">
                        Company
                        <Field
                          name="company_id"
                          render={({ field }) => (
                            <Select
                              showSearch
                              style={{ width: "100%" }}
                              placeholder="Choose Company"
                              {...field}
                              onChange={(value) => {
                                setFieldValue("company_id", value);
                              }}
                              onBlur={() => setFieldTouched("company_id", true)}
                              onSelect={(value, option) =>
                                this.handleSelectCompanytype(value, option)
                              }
                            >
                              {this.allCompanyType}
                            </Select>
                          )}
                        />
                        {errors.company_id && touched.company_id ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.company_id}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Terms and Condition
                        <Input
                          id="terms_and_condition"
                          placeholder="Card Terms and Condition"
                          value={values.terms_and_condition}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.terms_and_condition &&
                        touched.terms_and_condition ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.terms_and_condition}
                          </p>
                        ) : null}
                      </div>
                      <div className="formik-field-right">
                        Store Price
                        <Input
                          id="store_sell_price"
                          placeholder="Store Price"
                          type="number"
                          min={0}
                          value={values.store_sell_price}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.store_sell_price && touched.store_sell_price ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.store_sell_price}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    {/* Code for adding logo */}
                    {/* <div
                      style={{
                        display: "inline-block",
                        width: "100%",
                        marginTop: "20px",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        style={{ width: "40%", borderRadius: "5px" }}
                        onClick={this.showLogoModal.bind(this)}
                      >
                        <div
                          style={{
                            float: "left",
                            width: "0%",
                            marginLeft: "25px",
                          }}
                        >
                          <FileImageOutlined
                            style={{ width: "100%", fontSize: "15px" }}
                          />
                        </div>
                        Choose Card Logo
                      </Button>
                      {this.logoSrc ? (
                        <p
                          style={{
                            fontSize: "small",
                            margin: "0",
                            marginLeft: "5px",
                          }}
                        >
                          {this.logoSrc}
                        </p>
                      ) : null}
                      {this.state.viewLogo ? (
                        <AddCardIcon
                          refx={(e) => (this.logoModalRef = e)}
                          isLogo={true}
                          onUpload={this.uploadLogo}
                        />
                      ) : null}
                    </div>
                    <div width="100%" style={{ marginTop: "10px" }}>
                      {this.state.errors.uploadLogo != "" ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {this.state.errors.uploadLogo}
                        </p>
                      ) : null}
                    </div>
                    <div
                      style={{
                        marhinTop: "20px",
                        width: "100px",
                        borderRadius: "10px",
                      }}
                    >
                      {this.logoSrc && this.logoSrc !== "" ? (
                        <img
                          src={`${WEBURL}card/images/${this.logoSrc}`}
                          alt="CategoryLogo"
                          style={{ width: "100px" }}
                        />
                      ) : null}
                    </div> */}

                    <div
                      style={{
                        display: "inline-block",
                        width: "100%",
                        marginTop: "20px",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ float: "left", width: "10%" }}>
                        <PictureOutlined
                          style={{ width: "100%", fontSize: "30px" }}
                        />
                      </div>
                      <Button
                        style={{ width: "90%" }}
                        onClick={this.showLogoModal.bind(this)} //this.showViewModal.bind(this)
                      >
                        Choose Card Logo
                      </Button>
                      {this.logoSrc ? (
                        <p
                          style={{
                            fontSize: "small",
                            margin: "0",
                            marginLeft: "5px",
                          }}
                        >
                          {this.logoSrc}
                        </p>
                      ) : null}
                      {this.state.viewLogo ? (
                        <AddCardIcon
                          refx={(e) => (this.logoModalRef = e)}
                          isLogo={true}
                          onUpload={this.uploadLogo}
                        />
                      ) : null}
                    </div>
                    <div width="100%" style={{ marginTop: "10px" }}>
                      {this.state.errors.uploadLogo != "" ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {this.state.errors.uploadLogo}
                        </p>
                      ) : null}
                    </div>
                    <div
                      style={{
                        marhinTop: "20px",
                        width: "100px",
                        borderRadius: "10px",
                      }}
                    >
                      {this.logoSrc && this.logoSrc !== "" ? (
                        <img
                          src={`${WEBURL}card/images/${this.logoSrc}`}
                          alt="CategoryLogo"
                          style={{ width: "400px" }}
                        />
                      ) : null}
                    </div>

                    {/*  */}
                    <div
                      style={{
                        display: "inline-block",
                        width: "100%",
                        marginTop: "20px",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ float: "left", width: "10%" }}>
                        <PictureOutlined
                          style={{ width: "100%", fontSize: "30px" }}
                        />
                      </div>
                      <Button
                        style={{ width: "90%" }}
                        onClick={this.showViewModal.bind(this)} //this.showViewModal.bind(this)
                      >
                        Choose Card Icon
                      </Button>
                      {this.src ? (
                        <p
                          style={{
                            fontSize: "small",
                            margin: "0",
                            marginLeft: "5px",
                          }}
                        >
                          {this.src}
                        </p>
                      ) : null}
                      {this.state.view ? (
                        <AddCardIcon
                          refx={(e) => (this.viewModalRef = e)}
                          onUpload={this.uploadImage}
                        />
                      ) : null}
                    </div>
                    <div width="100%" style={{ marginTop: "10px" }}>
                      {this.state.errors.uploadImage != "" ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {this.state.errors.uploadImage}
                        </p>
                      ) : null}
                    </div>
                    <div
                      style={{
                        marhinTop: "20px",
                        width: "100px",
                        backgroundColor:
                          this.selectedColorType !== ""
                            ? this.selectedColorType
                            : "#ffffff",
                        borderRadius: "10px",
                      }}
                    >
                      {this.src && this.src !== "" ? (
                        <img
                          src={`${WEBURL}card/images/${this.src}`}
                          alt="CategoryIcon"
                          style={{ width: "100px" }}
                        />
                      ) : null}
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: "100%",
                        marginTop: "20px",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ float: "left", width: "10%" }}>
                        <PictureOutlined
                          style={{ width: "100%", fontSize: "30px" }}
                        />
                      </div>
                      <Button
                        style={{ width: "90%" }}
                        onClick={this.showImageViewModal.bind(this)} //this.showViewModal.bind(this)
                      >
                        Choose Card Image
                      </Button>
                      {this.imageSrc ? (
                        <p
                          style={{
                            fontSize: "small",
                            margin: "0",
                            marginLeft: "5px",
                          }}
                        >
                          {this.imageSrc}
                        </p>
                      ) : null}
                      {this.state.viewImage ? (
                        <AddCardIcon
                          refx={(e) => (this.imageViewModalRef = e)}
                          onUpload={this.uploadTypeImage}
                        />
                      ) : null}
                    </div>
                    <div width="100%" style={{ marginTop: "10px" }}>
                      {this.state.errors.uploadImage != "" ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {this.state.errors.uploadImage}
                        </p>
                      ) : null}
                    </div>
                    <div
                      style={{
                        marhinTop: "20px",
                        width: "100px",
                        borderRadius: "10px",
                      }}
                    >
                      {this.imageSrc && this.imageSrc !== "" ? (
                        <img
                          src={`${WEBURL}card/images/${this.imageSrc}`}
                          alt="CategoryImage"
                          style={{ width: "100px" }}
                        />
                      ) : null}
                    </div>

                    <Button
                      // type="primary"
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </ModalWrap>

        {this.state.openLikeModal && (
          <LikeCardModal
            isVisible={this.state.openLikeModal}
            onClose={() =>
              this.setState({
                openLikeModal: false,
              })
            }
            authToken={this.props.authToken}
            onSubmit={(values) => {
              this.setState({ likeCardData: values });
            }}
            likeCardData={likeCardData}
          />
        )}

        {this.state.openMintModal && (
          <MintCardModal
            isVisible={this.state.openMintModal}
            onClose={() =>
              this.setState({
                openMintModal: false,
              })
            }
            authToken={this.props.authToken}
            onSubmit={(values) => {
              this.setState({ mintCardData: values });
            }}
            mintCardData={mintCardData}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  adminData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setCardData: (data) => {
      dispatch({
        type: "SET_THIRD_PARTY_CARD_DATA",
        thirdPartyCardData: data,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddCardModal));
