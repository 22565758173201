import {
  Table,
  Button,
  Switch,
  Popconfirm,
  Tooltip,
  Avatar,
  Input,
  notification,
  Spin,
} from "antd";
import * as React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { PlusCircleOutlined, DeleteFilled } from "@ant-design/icons";
import { getBalanceRequestList } from "../../Graphs/balanceRequest/balanceRequestList";
import { AddBalanceIntoUserModal } from "./AddBalanceIntoUserModal";

class BalanceRequestTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
      startIndex: 0,
      limitNumber: 10,
      uploadRedeemData: null,
      request: {},
      isOpenModal: false,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      userPermission: {},
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.getLimitedCards = this.getLimitedCards.bind(this);
  }

  async componentDidMount() {
    const { setRouteName } = this.props;
    this.setState({
      data: this.props.data,
      isLoading: false,
      cardSelectedData: null,
    });
    if (this.props.loginUserData.access_permission != undefined) {
      let permission = JSON.parse(this.props.loginUserData.access_permission);
      console.log("permission ooo", permission);

      if (permission) {
        this.setState({
          userPermission: {
            edit:
              (permission &&
                permission["balanceRequest"] &&
                permission["balanceRequest"].edit) ||
              false,
            delete:
              (permission &&
                permission["balanceRequest"] &&
                permission["balanceRequest"].delete) ||
              false,
          },
        });
      }
    }
    setRouteName("BALANCE-REQUEST");
  }

  async getLimitedCards(pagination) {
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        this.balanceRequestPagination(start, end);
      }
    );
  }

  balanceRequestPagination = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setBalanceRequestData,
        setBalanceRequestDataTotal,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let updatedBalanceData;
      let page = start;
      console.log("start, end", start, end);
      updatedBalanceData = await getBalanceRequestList(authToken, page, end);

      if (updatedBalanceData.status == 200) {
        //set user Data
        await setBalanceRequestData(updatedBalanceData.data);
        await setBalanceRequestDataTotal(updatedBalanceData.total);
        this.setState({
          data: updatedBalanceData.data,
          totalBalanceReq: updatedBalanceData.total,
          isLoading: false,
        });
      } else if (updatedBalanceData.status == 401) {
        console.log("I amm 401");
        // await setLoginFlag(false);
        // await setUserData(null);
        // await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw updatedBalanceData && updatedBalanceData.message
          ? updatedBalanceData.message
          : "Error";
      }
    } catch (e) {
      console.log("!!!!!!!!TEST error in did mount", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  showModal(item) {
    console.log("showw modl item", item);
    this.setState(
      {
        request: item,
        isOpenModal: true,
      },
      () => {
        this.modalRef.show();
      }
    );
  }

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      render: (text, item, index) => {
        return (
          <div key={"sno" + index}>
            <span>{++index}</span>
          </div>
        );
      },
    },
    {
      title: "User",
      width: 150,
      key: "name",
      render: (text, item, index) => {
        return (
          <div key={"name" + index}>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column" style={{ marginTop: "5px" }}>
                {item.user.first_name + " " + item.user.last_name}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
      render: (text, item, index) => {
        return (
          <div key={"email" + index}>
            {item.user.email ? (
                item.user.email
            ) : (
              "-"
            )}
          </div>
        );
      },
    },

    {
      title: "Requested Amount (LYD)",
      key: "request balance",
      render: (text, item, index) => {
        return (
          <div key={"request" + index}>
              {/* <Button type="link" block onClick={()=>  { this.showWalletModal(item); } }>
                  view
                </Button> */}
              {item.request_balance}
          </div>
        );
      },
    },

    {
      title: "Approved Amount (LYD)",
      key: "approved balance",
      render: (text, item, index) => {
        return (
          <div key={"approved" + index}>
              {item.approved_balance}
          </div>
        );
      },
    },
    {
      title: "Approved Balance By",
      key: "approved_by",
      dataIndex: "approved_by",
      width: "200px",
      render: (text, item, index) => {
        return (
          <div key={"approved_by" + index}>
            {item.salesman == null
              ? item.distributor == null
                ? item.is_approved
                  ? "Admin"
                  : "-"
                : "D. " +
                  item.distributor.first_name +
                  " " +
                  item.distributor.last_name
              : "S. " +
                item.salesman.first_name +
                " " +
                item.salesman.last_name}
          </div>
        );
      },
    },
    {
      title: "Status",
      key: "status",
      render: (text, item, index) => {
        return (
          <div key={"status" + index}>
            {item.is_approved ? (
                "Completed"
            ) : (
              "Pending"
            )}
          </div>
        );
      },
    },

    {
      title: "Instant Balance",
      key: "instatant_balance",
      render: (text, item, index) => {
        return (
          <div key={"instatant_balance" + index}>
            {item.is_instant_balance && (
                "Immediate Balance Request"
            )}
          </div>
        );
      },
    },

    {
      title: "Actions",
      width: 130,
      key: "action",
      render: (text, item, index) => {
        return this.props.loginUserData.role == "Admin" ||
          this.props.loginUserData.role == "Company" ||
          this.props.loginUserData.role == "Distributor" ||
          (this.state.userPermission && this.state.userPermission.edit)
          ? !item.is_approved && (
              <div style={{ display: "inline-block", width: "100%" }}>
                {
                  <div mr="5px" style={{ float: "left" }}>
                    <Tooltip placement="bottom" title="Add">
                      <Button
                        shape="circle"
                        icon={<PlusCircleOutlined />}
                        onClick={() => {
                          console.log(
                            "isOpen value --- ",
                            this.state.isOpenModal
                          );
                          this.showModal(item);
                        }}
                      />
                    </Tooltip>
                  </div>
                }
              </div>
            )
          : null;
      },
    },
  ];

  handleTableChange(pagination, filters) {
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedCards(pagination)
    );
  }

  render() {
    const { isLoading } = this.state;
    const { authToken, total } = this.props;

    return isLoading ? (
      <div style={{ marginLeft: "20px" }}>
        <Spin
          size="large"
          style={{ marginLeft: "480px", marginTop: "130px" }}
        />
      </div>
    ) : (
      <div style={{ "overflow-x": "auto" }}>
        <Table
          loading={this.state.isLoading}
          rowKey={(record) => record._id}
          columns={this.columns}
          scroll={{ x: 1200 }}
          size={"small"}
          dataSource={this.state.data}
          pagination={{
            total: total,
            showSizeChanger: true,
            pageSize: this.state.pagination.pageSize,
            current: this.state.pagination.current,
            pageSizeOptions: ["1", "10", "25", "50", "100"],
            onChange: (e) => {
              console.log("onChanges pagination", e);
              if (e - 1) {
                console.log("enter");
                this.counter = this.tempCounter =
                  (e - 1) * this.state.pagination.pageSize;
                console.log("this.counter", this.counter);
                return;
              }
              this.counter = 0;
              this.tempCounter = 0;
            },
          }}
          onChange={this.handleTableChange}
        />

        {this.state.isOpenModal ? (
          <AddBalanceIntoUserModal
            // admin={false}
            authToken={authToken}
            refx={(e) => (this.modalRef = e)}
            request={this.state.request}
            // reqData={this.state.userData}
            updateRequestData={this.props.updateRequestData}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setBalanceRequestData: (balRequestData) => {
      dispatch({
        type: "SET_BALANCE_REQUEST_DATA",
        balanceData: balRequestData,
      });
    },
    setBalanceRequestDataTotal: (balRequestTotal) => {
      dispatch({
        type: "SET_BALANCE_REQUEST_DATA_TOTAL",
        totalBalanceRequest: balRequestTotal,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BalanceRequestTable));
