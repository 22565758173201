import { combineReducers } from 'redux';
import Customizer from './customizer.reducer';
import auth from './auth';
import master from './master';
import user from "./user";
import card from "./card";
import transaction from "./transaction";
import user_coin from "./user_coin";
import employee from "./employee";
import static_page from "./static_page";
import company from "./company";
import company_trn from "./company_trn";
import balanceRequest from "./balanceRequest";
import distributor from './distributor';
import sales from './sales';
import expenses from './expenses';


const reducers = combineReducers({
    Customizer,
    auth,
    user,
    master,
    card,
    transaction,
    user_coin,
    employee,
    static_page,
    company,
    company_trn,
    balanceRequest,
    distributor,
    sales,
    expenses,
});

export default reducers;