import React, { Fragment, Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button, Select, Spin, notification } from "antd";
import Title from "../User/Title";
import { Layers } from "react-feather";
import { alertError } from "../../utils/alert";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {EditModal} from "./EditModal";
import { getAllCategory } from "../../Graphs/Master/Category/listCategory";
import CountryRecordsTable from "./CategoryRecordsTable";

class Inventory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      inventoryList: [],
      inventoryListSize: 0,
      primaryColor: "#4466f2",
    //   addExpensesModal: false,
      pagination: {
        pageSize: 10,
        current: 1,
      },
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    // this.deleteExpenses = this.deleteExpenses.bind(this);
  }

  async componentDidMount() {
    const { setRouteName } = this.props;
    this.getInventoryList(0, 10, "");
    setRouteName("INVENTORY");
  }

  async getLimitedCards(pagination) {
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;
    this.getInventoryList(start, end, "");
  }

  handleTableChange(pagination, filters) {
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedCards(pagination)
    );
  }

  getInventoryList = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setInventoryData,
        setTotalInventoryData,
        inventory,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      const inventoryListData = await getAllCategory(authToken, start, end);
      if (inventoryListData.status == 200) {
        await setInventoryData(inventoryListData.data);
        await setTotalInventoryData(inventoryListData.total_inventory);

        this.setState({
          isLoading: false,
          inventoryList: inventoryListData.data,
          inventoryListSize: inventoryListData.count,
        });
      } else if (inventoryListData.status == 401) {
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw inventoryListData && inventoryListData.message
          ? inventoryListData.message
          : "Error";
      }
    } catch (e) {
      alertError("Inventory", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const { authToken, loginUserData } = this.props;
    const { isLoading, pagination } = this.state;
    const permission = JSON.parse(this.props.loginUserData.access_permission);

    return (
      <Fragment>
        <Title
          parent="Inventory Management"
          title="Inventory Management"
          icon={<Layers />}
          url="/inventory"
        />

        <div
          style={{
            marginTop: "20px",
            display: "inline-block",
            width: "100%",
            marginBottom: "20px",
            paddingLeft: "14px",
            paddingRight: "55px",
          }}
        >
          <div style={{ float: "right", cursor: "pointer", width: "100%" }}>
           
            {this.state.addExpensesModal &&
              (loginUserData.role === "Admin" || permission.inventory.edit) && (
                <EditModal
                  visible={this.state.addExpensesModal}
                  close={this.handleCancel}
                  authToken={this.props.authToken}
                  onSubmit={this.addSubmit}
                />
              )}
          </div>
        </div>

        <div className="container-fluid">
          {isLoading ? (
            <div style={{ marginLeft: "20px" }}>
              <Spin
                size="large"
                style={{ marginLeft: "480px", marginTop: "130px" }}
              />
            </div>
          ) : (
            <div>
              <CountryRecordsTable
                inventoryList={
                  this.props.inventory_data?.length > 0
                    ? this.props.inventory_data
                    : this.state.inventoryList
                }
                inventoryListSize={
                  this.props.total_inventory
                    ? this.props.total_inventory
                    : this.state.inventoryListSize
                }
                isLoading={isLoading}
                getInventoryList={this.getInventoryList}
                pagination={pagination}
                handleTableChange={this.handleTableChange}
                // delete={this.deleteExpenses}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setInventoryData: (inventoryData) => {
      dispatch({
        type: "SET_INVENTORY_DATA",
        inventoryData: inventoryData,
      });
    },
    setTotalInventoryData: (totalInventory) => {
      dispatch({
        type: "SET_INVENTORY_DATA_TOTAL",
        totalInventory: totalInventory,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Inventory));
