import * as React from "react";
import { Modal, Spin, notification } from "antd";
import { AddDistributorForm } from "./AddDistributorForm";
import { addDistributor } from "../../Graphs/distributor/addDistributor";
import { alertError } from "../../utils/alert";

export class AddDistributorModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            isLoading: false,
            closable: true,
        };
    }

    async componentDidMount() {
        if (this.props.refx) {
            this.props.refx(this);
        }
    }

    addSubmit = async (value) => {
        try {
            this.setState({
                isLoading: true,
            });

            let createObject = value;
            let distributorData = await addDistributor(this.props.authToken, createObject);

            if (distributorData.status == 200) {
                this.setState({
                    isLoading: true,
                });

                notification["success"]({
                    message: "Distributor",
                    description: "Successfully Added",
                });

                this.props.reloadData();

                return 1;
            } else {
                throw distributorData.message ? distributorData.message : "Error";
            }
        } catch (error) {
            this.setState({
                isLoading: false,
            });
            alertError("Distributor",error)
            return -1;
        }
    };

    handleOk = (e) => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = (e) => {
        this.hide();
    };

    show = () => {
        this.setState({
            visible: true,
        });
    };

    hide() {
        this.setState({
            visible: false,
        });
        //this.props.onClose();
    }

    render() {
        const { primaryColor } = this.props;
        return (
            <React.Fragment>
                <div
                    onClick={this.show}
                    style={{
                        cursor: "pointer",
                        width: "100px",
                        backgroundColor: primaryColor,
                        color: "white",
                        padding: "7px 0px",
                        textAlign: "center",
                        borderRadius: "50px",
                    }}
                >
                    ADD
                </div>

                <Modal
                    width="600px"
                    closable={this.state.closable}
                    open={this.state.visible}
                    title="Add Distributor"
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                    destroyOnClose={true}
                >
                    {this.state.isLoading ? (
                        <div style={{ justifyContent: "center" }}>
                            <Spin />
                        </div>
                    ) : (
                        <AddDistributorForm
                            authToken={this.props.authToken}
                            onClose={this.hide}
                            onSubmit={this.addSubmit}
                            handleCancel={this.handleCancel}
                        />
                    )}
                </Modal>
            </React.Fragment>
        );
    }
}
