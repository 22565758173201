import { Table, Tooltip, notification, Spin } from "antd";
import * as React from "react";
import { get } from "lodash";
import { getAllCardRedeem } from "../../Graphs/Card/listCardRedeem";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { alertError } from "../../utils/alert";
import TableWrap from "../common/Table";
var moment = require("moment");

class RedeemCodeData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataTotal: 0,
      isLoading: false,
      startIndex: 0,
      limitNumber: 5,
      pagination: {
        pageSize: 5,
        current: 1,
      },
    };
    this.dateFormat = this.dateFormat.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  componentDidMount() {
    this.listCardRedeemData(0, 5);
  }

  getLimitedRedeem = async (pagination) => {
    console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        this.listCardRedeemData(start, end);
      }
    );
  };

  listCardRedeemData = async (start, end) => {
    try {
      const { authToken } = this.props;
      this.setState({
        isLoading: true,
      });

      let cardRedeemData = await getAllCardRedeem(
        authToken,
        this.props.data.id,
        start,
        end,
        { token: this.props.otpToken }
      );

      if (cardRedeemData.status == 200) {
        this.setState({
          isLoading: false,
          data: cardRedeemData.data,
          dataTotal: cardRedeemData.total,
        });
      } else if (cardRedeemData.status == 401) {
        this.props.expireToken();
        this.props.onClose();
        throw cardRedeemData && cardRedeemData.message
          ? cardRedeemData.message
          : "Error";
      } else {
        this.props.onClose();
        throw cardRedeemData && cardRedeemData.message
          ? cardRedeemData.message
          : "Error";
      }
    } catch (e) {
      alertError("REDEEM", e);
      // this.props.sendOTP();
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleTableChange(pagination, filters) {
    console.log("outer onchange iusers", pagination);
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedRedeem(pagination)
    );
  }

  dateFormat(date) {
    var oldDate = new Date(date),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.add(2, "hours").format("Do MMM YYYY");

    return newDate;
  }

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      fixed: true,
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "Card Name",
      key: "card_name",
      fixed: true,
      render: (text, item, index) => {
        console.log("user record --> ", item);
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <strong>
                  {" "}
                    <div>
                      {item.card
                        ? item.card.name
                          ? item.card.name
                          : "-"
                        : "-"}
                    </div>
                </strong>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "User Name",
      key: "user_name",
      render: (text, item, index) => {
        console.log("user record --> ", item);
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                <strong>
                  {" "}
                    <div>
                      {item.user
                        ? item.user.first_name
                          ? item.user.first_name
                          : "-"
                        : "-"}
                    </div>
                </strong>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Serial Number",
      key: "serial_num",
      dataIndex: "serial_number",
      render: (text, item, index) => {
        return (
          <div>
            {item.serial_number ? (
                item?.serial_number
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Redeem Code",
      key: "redeem_code",
      dataIndex: "redeem_code",
      render: (text, item, index) => {
        return (
          <div>
            {item.redeem_code ? (
               item.redeem_code
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Added By",
      key: "added_by",
      dataIndex: "added_by",
      render: (text, item, index) => {
        console.log("add test", item);
        return (
          <div>
            {item.redeem_code ? (
                item.added_by
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Paid",
      key: "paid",
      render: (text, item, index) => {
        console.log("user record --> ", item);
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                  <div>
                    {item.is_paid
                      ? "PAID"
                      : item.user_transaction !== null
                      ? "RESERVED"
                      : "UNPAID"}
                  </div>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Order Code",
      key: "order_code",
      render: (text, item, index) => {
        console.log("user record --> ", item);
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                  <div>
                    {item.is_paid
                      ? item.user_transaction !== null
                        ? item.user_transaction.order_code
                        : item.user_wallet_transaction !== null
                        ? item.user_wallet_transaction.order_code
                        : "-"
                      : "-"}
                  </div>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Reference Id",
      key: "reference_id",
      render: (text, item, index) => {
        console.log("user record --> ", item);
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column">
                  <div>
                    {item.is_paid
                      ? item.user_transaction !== null
                        ? item.user_transaction.reference_id
                        : item.user_wallet_transaction !== null
                        ? item.user_wallet_transaction.reference_id
                        : "-"
                      : "-"}
                  </div>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  render() {
    const { isLoading, data, dataTotal } = this.state;

    return isLoading ? (
      <div style={{ marginLeft: "20px" }}>
        <Spin size="large" style={{ margin: "0 auto", width: "100%" }} />
      </div>
    ) : (
      <div style={{ "overflow-x": "auto" }}>
        <TableWrap
          data={data}
          columns={this.columns}
          isLoading={this.state.isLoading}
          total={dataTotal}
          pagination={this.state.pagination}
          handleTableChange={this.handleTableChange}
          scroll={{ x: 1000 }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  listCardData: state.card.listCardData,
  listCardDataTotal: state.card.listCardDataTotal,
  searchLoader: state.auth.searchLoader,
  searchText: state.auth.searchText,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListCardData: (listCardData) => {
      dispatch({
        type: "SET_LIST_CARD_DATA",
        listCardData: listCardData,
      });
    },
    setListCardDataTotal: (listCardDataTotal) => {
      dispatch({
        type: "SET_LIST_CARD_DATA_TOTAL",
        listCardDataTotal: listCardDataTotal,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RedeemCodeData));
