import {
  emailRegx,
  fnameRegx,
  lnameRegx,
  mobileRegx,
  numberRegx,
  passwordRegx,
  priceRegx,
  spaceRegx,
} from "./regx";

export const fnameValidation = (value) =>  {
  if (!fnameRegx.test(value)) {
    return true;
  }
}

export const lnameValidation = (value) =>  {
  if (!lnameRegx.test(value)) {
    return true;
  }
}


export const passwordValidation = (value) => {
  if (!passwordRegx.test(value)) {
    return true;
  }
};

export const otpValidation = (value) => {
  if (!numberRegx.test(parseInt(value))) {
    return true;
  }
};

export const mobileValidation = (value) => {
  if (!mobileRegx.test(parseInt(value))) {
    return true;
  }
};

export const mobileLengthValidation = (value) => {
  if (value.length > 12) {
    return true;
  }
};

export const emailValidation = (value) => {
  if (!emailRegx.test(value)) {
    return true;
  }
};

export const priceValidation = (value) => {
  if (!priceRegx.test(value)) {
    return true;
  }
};

export const spaceValidation = (value) => {
  // console.log("regx check",spaceRegx.test(value));
  if (!spaceRegx.test(value)) {
    return true;
  }
}