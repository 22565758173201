import { callApiToServer } from '../callApi';

export async function getAllCardRedeem(
  authtoken,
  cardId,
  skipNumber,
  limitNumber,
  payload
) {
  try{

    let body = JSON.stringify(payload);

    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization" : "Berear " + authtoken
    }
  
    let endUrl = "v1/auth/list-card-redeem-using-cardid/" + cardId + "/" + skipNumber + "/" + limitNumber;
  
    let responseData = await callApiToServer(body, header, "POST", endUrl);
      
    return responseData;
  }catch(err) {
    throw err;
  }
}
