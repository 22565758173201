import {
  Button,
  DatePicker,
  notification,
  Popconfirm,
  Tag,
  Tooltip,
} from "antd";
import moment from "moment";
import React, { Component } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { getCollectBalanceFromUser } from "../../Graphs/sales/getCollectBalanceFromUser";
import ModalWrap from "../common/modal";
import TableWrap from "../common/Table";
import { deleteCollectUserReport } from "../../Graphs/sales/DeleteCollectUserReport";
import { alertError } from "../../utils/alert";
import { dateFormat } from "../../utils/general";

export default class CollectBalanceFromUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collectAmountList: [],
      date: "",
      totalCollect: 0,
      isLoading: false,
      deleteItem: null,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
    };
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      date: this.props.date,
    });
    let page = 0;
    let limit = 10;
    this.getCollectAmountFromUserList(page, limit, this.props.date);
  }

  getCollectAmountFromUserList = async (page, limit, date) => {
    try {
      const { authToken, history } = this.props;

      let salesId = this.props.data
        ? this.props.date
          ? this.props.data.salesman_id
          : this.props.data.id
        : "";
      let collectData = await getCollectBalanceFromUser(
        authToken,
        page,
        limit,
        salesId,
        date
      );

      this.setState({
        isLoading: true,
      });
      if (collectData.status == 200) {
        this.setState({
          collectAmountList: collectData.data,
          filterData: collectData.data,
          totalCollect: collectData.length,
          // totalOutstandingBalance:collectData.total_outstanding_balance,
          isLoading: false,
        });
      } else if (collectData.status == 401) {
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw collectData && collectData.message
          ? collectData.message
          : "Error";
      }
    } catch (e) {
      alertError("SALES", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  
  async getLimitedData(pagination) {
    const page = pagination.current * pagination.pageSize - pagination.pageSize;
    const limit = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: page,
        limitNumber: limit,
      },
      async () => {
        this.getCollectAmountFromUserList(page, limit, this.state.date);
      }
    );
  }

  handleTableChange(pagination, filters) {
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedData(pagination)
    );
  }

  async deleteCollectRecord(item) {
    try {
      let deleteData = await deleteCollectUserReport(
        this.props.authToken,
        item.id
      );

      if (deleteData.status == 200) {
        notification["success"]({
          message: "Sales User",
          description: deleteData.message,
        });
        this.getCollectAmountFromUserList(0, 10, this.state.date);
        return 1;
      } else {
        throw deleteData.message;
      }
    } catch (error) {
      alertError("SALES", error);
      return -1;
    }
  }

  columns = [
    {
      title: "Sr. No.",
      width: 100,
      key: "serial_number",
      render: (text, item, index) => {
        return (
          <div key={"sno" + index}>
            <span>{++index}</span>
          </div>
        );
      },
    },
    {
      title: "User Name",
      key: "name",
      width: 150,
      dataIndex: "name",
      render: (text, item, index) => {
        return (
          <div key={"name" + index}>
            {item.user ? item.user.first_name + " " + item.user.last_name : "-"}
          </div>
        );
      },
    },
    {
      title: "Shop Name",
      key: "shop_name",
      width: 150,
      dataIndex: "shop_name",
      render: (text, item, index) => {
        return (
          <div key={"shop_name" + index}>
            {item.user ? item.user.shop_name : "-"}
          </div>
        );
      },
    },
    {
      title: "Amount (LYD)",
      key: "amount",
      width: 150,
      render: (text, item, index) => {
        return <div>{item.amount ? item.amount : "-"}</div>;
      },
    },

    {
      title: "Date",
      width: 200,
      key: "createdAt",
      render: (text, item, index) => {
        return (
          <div key={"createdAt" + index}>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column" style={{ marginTop: "5px" }}>
                {item.createdAt !== null ? dateFormat(item.createdAt) : "-"}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Actions",
      width: 130,
      key: "action",
      render: (text, item, index) => {
        return (
          <div style={{ display: "inline-block", width: "100%" }}>
            {
              <div mr="5px" style={{ float: "left" }}>
                <Tooltip placement="bottom" title="Delete">
                  <Popconfirm
                    onConfirm={this.deleteCollectRecord.bind(this, item)}
                    title="Are you sure you want to delete this record?"
                  >
                    <Button shape="circle" icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Tooltip>
              </div>
            }
          </div>
        );
      },
    },
  ];
  render() {
    return (
      <ModalWrap
        visible={this.props.visible}
        closable={this.props.onClose}
        title="Collect Balance from User"
        onOk={this.props.handleOk}
        onCancel={this.props.handleCancel}
        isLoading={this.props.isLoading}
        width="1000px"
      >
        <div style={{ cursor: "pointer", marginBottom: "20px" }}>
          <DatePicker
            name="date"
            onChange={(date, dateString) => {
              this.setState({
                date: dateString,
              });
              this.getCollectAmountFromUserList(0, 10, dateString);
            }}
            defaultValue={this.props.date && moment(this.props.date)}
            disabledDate={(current) => {
              return moment().add(0, "days") <= current;
            }}
            style={{ width: "200px" }}
          />
        </div>
        <TableWrap
          data={this.state.collectAmountList}
          columns={this.columns}
          isLoading={this.state.isLoading}
          total={this.state.totalCollect}
          pagination={this.state.pagination}
          handleTableChange={this.handleTableChange}
          scroll={{ x: 800 }}
        />
      </ModalWrap>
    );
  }
}
