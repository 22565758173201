import { callGetApiToServer } from '../callApi';

export async function getCollectBalanceFromUser(
    authtoken,
    skip,
    limit,
    salesId,
    date
) {
  try{
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization" : "Berear " + authtoken
    }
  
    let endUrl = `v1/auth/sales/sales-collected-from-users-trn/${salesId}/${skip}/${limit}?date=${date}`;
    console.log("endUrl printed here", endUrl);
  
    let responseData = await callGetApiToServer(header, "GET", endUrl);
      
    return responseData;
  }catch(err) {
    throw err;
  }
}
