/* eslint-disable */

import { Modal, Button, Divider } from "antd";
import * as React from "react";
import { Layout, Input, TimePicker, Select, Checkbox } from "antd";
import { Formik, FieldProps, Field } from "formik";
import * as moment from "moment";
import styled from "styled-components";
import { StyleSelect } from "../../assets/css/style";
import { notification } from "antd";
import * as la from "lodash";
import {
  emailValidation,
  fnameValidation,
  lnameValidation,
  mobileLengthValidation,
  passwordValidation,
  spaceValidation,
} from "../../utils/validations";
import { errorMessages } from "../../utils/validationMessages";
import { mobileValidation } from "../../utils/validations";
import { spaceRegx } from "../../utils/regx";

const CheckboxGroup = Checkbox.Group;
const { Content } = Layout;
const Option = Select.Option;
const { TextArea } = Input;
var amenities = [];

const StyleDivider = styled(Divider)`
  &.ant-divider-horizontal {
    margin: 14px 0px;
  }
`;

export class AddUserModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      data: [],
      index: null,
      isLoading: true,
      visible: false,
      cityData: [],
    };
  }

  input;

  async componentDidMount() {
    if (this.props.refx) {
      this.props.refx(this);
    }
  }

  validateForm = (values) => {
    const errors = {};
    if (!values.first_name) {
      errors.first_name = errorMessages.first_name_required;
    } else if (fnameValidation(values.first_name)) {
      errors.first_name = errorMessages.fname_capital;
    }

    if (!values.last_name) {
      errors.last_name = errorMessages.last_name_required;
    } else if (lnameValidation(values.last_name)) {
      errors.last_name = errorMessages.lname_capital;
    }

    if (!values.shop_name) {
      errors.shop_name = "Shop name is required";
    } else if (spaceValidation(values.field)) {
      errors.shop_name = errorMessages.space_validation;
    }

    if (!values.address) {
      errors.address = "Address is required";
    }

    if (!values.email) {
      errors.email = errorMessages.email_required;
    } else if (emailValidation(values.email)) {
      errors.email = errorMessages.email_validation;
    }

    if (!values.phone) {
      errors.phone = errorMessages.mobile_required;
    } else if (mobileLengthValidation(values.phone)) {
      errors.phone = errorMessages.mobile_length_validation;
    } else if (mobileValidation(values.phone)) {
      errors.phone = errorMessages.mobile_validation;
    }

    if (!values.password) {
      errors.password = errorMessages.password_required;
    } else if (passwordValidation(values.password)) {
      errors.password = errorMessages.password_validation;
    }

    if (this.props.salesmanData) {
      if (!values.note) {
        errors.note = "Note is required";
      }
      if (!values.selectSales) {
        errors.selectSales = "Select salesman is required";
      }
    }

    return errors;
  };

  handleSubmit = (values, action) => {
    console.log("obj value ======", values)
    let obj = { ...values, cityId: values.cityId ? values.cityId : "" };
    try {
      this.props.onSubmit(obj);
      this.setState({
        visible: false,
      });
    } catch (error) {}
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const { primaryColor } = this.props;
    const { permissionList } = this.state;

    return (
      <div>
        <div
          onClick={this.showModal}
          style={{
            cursor: "pointer",
            width: "140px",
            backgroundColor: "#3a3e78",
            color: "white",
            padding: "7px",
            textAlign: "center",
            borderRadius: "50px",
          }}
        >
          Add New User
        </div>
        <Modal
          destroyOnClose={true}
          title="Add New User"
          open={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
        >
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                first_name: "",
                last_name: "",
                shop_name: "",
                email: "",
                phone: "",
                password: "",
                address: "",
                note: "",
                selectSales: "",
                selectSalesmanId: "",
                city: "--- Select City ---",
                cityId: "",
              }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return (
                  <div>
                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        First Name
                        <Input
                          id="first_name"
                          placeholder="First Name"
                          value={values.first_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.first_name && touched.first_name ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.first_name}
                          </p>
                        ) : null}
                      </div>
                      <div className="formik-field-right">
                        Last Name
                        <Input
                          id="last_name"
                          placeholder="Last Name"
                          value={values.last_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.last_name && touched.last_name ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.last_name}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Phone
                        <Input
                          id="phone"
                          placeholder="Phone"
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.phone && touched.phone ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.phone}
                          </p>
                        ) : null}
                      </div>
                      <div className="formik-field-right">
                        Email
                        <Input
                          id="email"
                          placeholder="Email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.email && touched.email ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.email}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Shop Name
                        <Input
                          id="shop_name"
                          placeholder="Shop Name"
                          value={values.shop_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.shop_name && touched.shop_name ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.shop_name}
                          </p>
                        ) : null}
                      </div>
                      <div className="formik-field-right">
                        Password
                        <Input
                          id="password"
                          placeholder="Password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="password"
                        />
                        {errors.password && touched.password ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.password}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Address
                        <TextArea
                          id="address"
                          placeholder="Address"
                          value={values.address}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.address && touched.address ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.address}
                          </p>
                        ) : null}
                      </div>

                      <div className="formik-field-right">
                        City
                        <Field
                          name="city"
                          render={({ field }) => (
                            <StyleSelect
                              placeholder="Select City"
                              style={{ width: "100%" }}
                              name="city"
                              {...field}
                              onBlur={() => setFieldTouched("city", true)}
                              onSelect={(value, option) => {
                                setFieldValue("city", value);
                                setFieldValue("cityId", option.id);
                              }}
                              options={this.props.cityData}
                            />
                          )}
                        />
                      </div>

                      <div className="formik-field_wrap">
                        {this.props.salesmanData && (
                          <div className="formik-field-left">
                            Assign Salesman
                            <Field
                              name="selectSales"
                              render={({ field }) => (
                                <Select
                                  style={{ width: "100%" }}
                                  placeholder="Select Sales"
                                  name="selectSales"
                                  {...field}
                                  onBlur={() =>
                                    setFieldTouched("selectSales", true)
                                  }
                                  onSelect={(value, option) => {
                                    setFieldValue("selectSales", value);
                                    setFieldValue(
                                      "selectSalesmanId",
                                      option.id
                                    );
                                  }}
                                  options={this.props.salesmanData}
                                ></Select>
                              )}
                            />
                            {errors.selectSales && touched.selectSales ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.selectSales}
                              </p>
                            ) : null}
                          </div>
                        )}
                      </div>
                    </div>
                    {this.props.salesmanData && (
                      <div className="formik-field_wrap">
                        Note
                        <TextArea
                          rows={4}
                          name="note"
                          placeholder="Note"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.note && touched.note ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.note}
                          </p>
                        ) : null}
                      </div>
                    )}

                    <Button
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </Modal>
      </div>
    );
  }
}
