import * as React from "react";
import { notification, Button, Select } from "antd";
import { Field, Form, Formik } from "formik";
import ModalWrap from "../common/modal";
import { getSalesUserList } from "../../Graphs/sales/getSalesUserList";
import { assignUserToSales } from "../../Graphs/sales/AssignUserToSales";
import { alertError } from "../../utils/alert";

export class AssignSalesModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      isLoading: false,
      closable: true,
      selectedSalesValue: "",
      salesData: [],
    };
  }

  async componentDidMount() {
    if (this.props.refx) {
      this.props.refx(this);
    }

    let page = 0;
    let limit = 100000;
    let salesList = await getSalesUserList(this.props.authToken, page, limit);
    if (salesList.status == 200) {
      this.setState({
        salesData: salesList.data.map((i) => ({
          ...i,
          label: i.first_name + " " + i.last_name,
          value: i.first_name + " " + i.last_name,
        })),
      });
    }
  }

  handleSubmit = async (values) => {
    let body = {
      user_id: this.props.SelectedData.id,
      salesman_id: this.state.selectedSalesValue.id,
    };
    let response = await assignUserToSales(this.props.authToken, body);
    if (response.status == 200) {
      notification["success"]({
        message: response.message,
      });
      this.setState = {
        visible: false,
      };
      this.props.reload();
      this.props.onClose();
    } else {
      alertError("USER", response.message);
    }
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.hide();
  };

  show() {
    this.setState({
      visible: true,
    });
  }

  hide() {
    this.setState({
      visible: false,
    });
    this.props.onClose();
  }

  handleSelectSales(value, option) {
    this.setState({
      selectedSalesValue: option,
    });
  }

  render() {
    const { SelectedData } = this.props;

    console.log("SelectedData.salesman", SelectedData.salesman);
    return (
      <ModalWrap
        visible={this.state.visible}
        closable={this.state.closable}
        title="Assign Salesman"
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        isLoading={this.state.isLoading}
      >
        <Formik
          initialValues={{
            select_distributor: SelectedData.salesman
              ? SelectedData.salesman.first_name +
                " " +
                SelectedData.salesman.last_name
              : "",
          }}
          onSubmit={this.handleSubmit}
        >
          {({ values, handleSubmit, setFieldTouched, setFieldValue }) => {
            return (
              <Form>
                {
                  <>
                    <div className="formik-field_wrap">
                      <div className="formik-field-wrap">
                        Select Sales
                        <Field
                          name="selectSales"
                          render={({ field }) => (
                            <Select
                              style={{ width: "100%" }}
                              placeholder="Select Sales"
                              value={values.select_distributor}
                              defaultValue={values.select_distributor}
                              onChange={(value) => {
                                setFieldValue("selectSales", value);
                              }}
                              onBlur={() =>
                                setFieldTouched("selectSales", true)
                              }
                              onSelect={(value, option) =>
                                this.handleSelectSales(value, option)
                              }
                              options={this.state.salesData}
                              {...field}
                            ></Select>
                          )}
                        />
                      </div>
                    </div>
                    <Button
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  </>
                }
              </Form>
            );
          }}
        </Formik>
      </ModalWrap>
    );
  }
}
