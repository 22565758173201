import React, { Component } from "react";
import { withRouter } from "react-router";
import { alertError } from "../../utils/alert";
import { connect } from "react-redux";
import { financialReportAPI } from "../../Graphs/Report/financialReport";
import { formatePrice } from "../../utils/general";
import TableWrap from "../common/Table";

class FinancialReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      financialData: null,
      isTableOpen: false,
    };
  }

  async componentDidMount() {
    try {
      const { authToken } = this.props;

      let financialReport = await financialReportAPI(authToken);
      this.setState({
        isLoading: true,
        financialData: financialReport.data,
      });
      if (financialReport.status == 200) {
        this.setState({
          isLoading: false,
          isTableOpen: true,
        });
      } else {
        throw financialReport && financialReport.message
          ? financialReport.message
          : "Error";
      }
    } catch (e) {
      alertError("REPORT", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  columns = [
    {
      title: "",
      dataIndex: "name",
    },
    {
      title: "",
      dataIndex: "data",
    },
    {
      title: "",
      dataIndex: "balance",
    },
  ];

  render() {
    const { financialData, isTableOpen } = this.state;

    let allData = [];

    if (financialData) {
      const userCoin = financialData?.user_coin?.map((item) => {
        return {
          name: "User Coin",
          data: item.coin.name,
          balance: `${formatePrice(item?.total_amount)}`,
        };
      });

      const data = [
        {
          name: "Distributor Coin",
          data: "-",
          balance: `${formatePrice(financialData?.distributor_coin)}`,
        },
        {
          name: "Salesman Coin",
          data: "-",
          balance: `${formatePrice(financialData?.salesman_coin)}`,
        },
        {
          name: "Salesman Collection",
          data: "-",
          balance: `${formatePrice(financialData?.saleman_collected)}`,
        },
        {
          name: "Salesman Outstanding	",
          data: "-",
          balance: `${formatePrice(financialData?.salesman_outstanding)} LYD`,
        },
        {
          name: "User Outstanding	",
          data: "-",
          balance: `${formatePrice(financialData?.user_outstanding)} LYD`,
        },
        {
          name: "Total Sold Card Amount",
          data: "-",
          balance: `${formatePrice(financialData?.total_sold_card_amount)} LYD`,
        },
        {
          name: "Total Sold Card Count",
          data: "-",
          balance: `${financialData?.total_sold_card_count}`,
        },
        {
          name: "Available Admin Inventory",
          data: "-",
          balance: `${financialData.available_admin_inventory}`,
        },
        {
          name: "Available Company Inventory",
          data: "-",
          balance: `${financialData.available_company_inventory}`,
        },
      ];

      allData = [...userCoin, ...data];
    }

    return (
      <>
        {isTableOpen && allData.length > 0 && (
          <TableWrap
            style={{ width: "600px" }}
            columns={this.columns}
            data={allData}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
});

export default connect(mapStateToProps)(withRouter(FinancialReport));
