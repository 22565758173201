import { callGetApiToServer } from '../callApi';

export async function getSalesUserList(
    authtoken,
    skip,
    limit,
    searchData="",
) {
  try{
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization" : "Berear " + authtoken
    }
  
    let endUrl = `v1/auth/sales/list/${skip}/${limit}?search_text=${searchData}`;
    console.log("endUrl printed here", endUrl);
  
    let responseData = await callGetApiToServer(header, "GET", endUrl);
      
    return responseData;
  }catch(err) {
    throw err;
  }
}
