import React, { Fragment, Component } from "react";
import {
  Table,
  Tooltip,
  notification,
  Spin,
  Select,
  Input,
  Button,
  Modal,
  DatePicker,
  Tabs,
} from "antd";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Breadcrumb from "../common/breadcrumb";
import { listCardReport } from "../../Graphs/Report/listCardReport";
import Acquisitions from "./acquisitions";
import { alertError } from "../../utils/alert";
import { dateFormat } from "../../utils/general";
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

var moment = require("moment");

const TabPane = Tabs.TabPane;

class ListCardReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      cardData: [],
      cardDataTotal: 0,
    };
  }

  listCardListData = async (startDate, endDate) => {
    try {
      const {
        userData,
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      if (!startDate || !endDate) {
        alertError("REPORT", "Please select a range of date");
        return;
      }

      let cardListData = await listCardReport(authToken, startDate, endDate);
      console.log("!!!!!!!!!!!!cardListData", cardListData);

      if (cardListData.status == 200) {
        this.setState({
          isLoading: false,
          cardData: cardListData.data,
          cardDataTotal: cardListData.total,
        });
      } else if (cardListData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw cardListData && cardListData.message
          ? cardListData.message
          : "Error";
      }
    } catch (e) {
      alertError("REPORT", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };


  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      fixed: true,
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "Card Name",
      key: "CardName",
      render: (text, item, index) => {
        return <div>{item.card ? item.card.name : "-"}</div>;
      },
    },
    {
      title: "Amount",
      key: "Amount",
      render: (text, item, index) => {
        return <div>{String(item.total_amount.toFixed(2))}</div>;
      },
    },
    {
      title: "Sold Card",
      key: "CardCount",
      render: (text, item, index) => {
        return <div>{String(item.total_count)}</div>;
      },
    },
  ];

  financialYear = (value) => {
    var currentMonth = new Date().getMonth();
    let year = moment().year();
    if (currentMonth < 3) {
      year -= 1;
    }

    const start = moment(`1/4/${year}`, "DD/MM/YYYY");
    const end = value ? moment() : moment(`31/03/${year + 1}`, "DD/MM/YYYY");
    return [start, end];
  };

  previousFinancialYear = () => {
    var currentMonth = new Date().getMonth();
    let year = moment().year();
    const start = moment(`1/4/${year - 1}`, "DD/MM/YYYY");
    const end = moment(`31/3/${year}`, "DD/MM/YYYY");
    return [start, end];
  };

  resetCardData = () => {
    this.setState({
      cardData: [],
      cardDataTotal: 0,
    });
  };

  onChangeDate = (date) => {
    if (!date || !date.length) {
      this.resetCardData();
      this.setState({
        startDate: null,
        endDate: null,
      });
    } else {
      this.setState({
        startDate: date[0].format("YYYY-MM-DD HH:mm:ss"),
        endDate: date[1].format("YYYY-MM-DD HH:mm:ss"),
      });
    }
  };

  onFilter = async () => {
    try {
      const { startDate, endDate } = this.state;
      await this.listCardListData(startDate, endDate);
    } catch (e) {
      console.log("!!!!!!!error printed here onFilter", e);
    }
  };

  generateCsv = async () => {
    try {
      const { cardData, startDate, endDate } = this.state;
      if (!(cardData && cardData.length > 0)) {
        return;
      }

      this.setState({
        isLoading: true,
      });

      let csvArray = [];

      csvArray.push(["Sr.No.", "Card Name", "Amount", "Sold Card"]);

      cardData.map((data, index) => {
        csvArray.push([
          index + 1,
          data.card ? data.card.name : "NA",
          data.total_amount.toFixed(2),
          data.total_count,
        ]);
      });

      const rows = csvArray;
      let csvName = `card_report_${dateFormat(startDate)}-${dateFormat(
        endDate
      )}.csv`;

      let csvContent = "data:text/csv;charset=utf-8,";

      rows.forEach(function (rowArray) {
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
      });

      var encodedUri = encodeURI(csvContent);
      // // // // window.open(encodedUri);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", csvName);
      document.body.appendChild(link); // Required for FF

      link.click();
    } catch (e) {
      console.log("error in generateCsv function", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const { isLoading, searchText, cardData, cardDataTotal } = this.state;

    return (
      <Fragment>
        <div>
          <Tabs destroyInactiveTabPane="true">
            <TabPane tab="Card Table Data" key="1">
              <div
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  display: "inline-block",
                }}
              >
                <div style={{ float: "left", width: "50%" }}>
                  <RangePicker
                    format="DD-MM-YYYY"
                    onChange={this.onChangeDate}
                    ranges={{
                      Today: [
                        moment().startOf("day"),
                        moment().add(1, "days").endOf("day"),
                      ],
                      Yesterday: [
                        moment().subtract(1, "days").startOf("day"),
                        moment().endOf("day"),
                        // moment()
                        //   .subtract(1, "days")
                        //   .endOf("day")
                      ],
                      "This Week": [
                        moment().startOf("week"),
                        moment().endOf("week"),
                      ],
                      "This Financial Year": this.financialYear(0),
                      "Previous Financial Year": this.previousFinancialYear(),
                    }}
                    disabledDate={(current) => {
                      return moment().add(0, "days") <= current;
                    }}
                  />
                </div>
                <div style={{ float: "left" }}>
                  <Button
                    style={{
                      marginTop: "0px",
                      background: "#5C6AC4",
                      color: "#fff",
                      cursor: "pointer",
                    }}
                    onClick={this.onFilter}
                  >
                    Filter
                  </Button>

                  <Button
                    style={{
                      marginTop: "0px",
                      background: "#5C6AC4",
                      color: "#fff",
                      cursor: "pointer",
                      marginLeft: "15px",
                    }}
                    onClick={() => {
                      this.generateCsv();
                    }}
                  >
                    Generate CSV
                  </Button>
                </div>
              </div>
              <div
                style={{
                  "overflow-x": "auto",
                  width: "100%",
                  display: "inline-block",
                }}
              >
                <Table
                  loading={this.state.isLoading}
                  rowKey={(record) => record._id}
                  columns={this.columns}
                  size={"small"}
                  scroll={{ x: 900 }}
                  // dataSource={this.state.data}
                  dataSource={cardData}
                  pagination={false}
                />
              </div>
            </TabPane>
            <TabPane tab="Card Graph Data" key="2">
              <div>
                <Acquisitions />
              </div>
            </TabPane>
          </Tabs>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
  }; 
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ListCardReport));
