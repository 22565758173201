import React, { Component, createRef } from "react";
import { Form, Input, Button } from "antd";
import { Field, Formik } from "formik";
import { otpValidation } from "../../utils/validations";
import { errorMessages } from "../../utils/validationMessages";
import OTPInput from "react-otp-input";
import { verifyOTP } from "../../Graphs/Login/VerifyOTP";

export default class OTP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      valuesArray: [],
      otp: "",
    };
  }

  render() {
    const { otp } = this.state;
    return (
      <div>
        <OTPInput
          value={otp}
          onChange={(e) => this.setState({ otp: e })}
          numInputs={6}
          renderSeparator={<span>-</span>}
          renderInput={(props) => <input {...props} />}
          shouldAutoFocus={true}
          inputStyle={{
            width: "40px",
            height: "40px",
            textAlign: "center",
            margin: "5px 11px",
          }}
        />
        <div style={{ margin: "20px auto 0px", width: "35%" }}>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => this.props.onSubmit(this.state.otp)}
            style={{ width: "100%", height: "40px" }}
          >
            Verify OTP
          </Button>
        </div>
      </div>
    );
  }
}
