import moment from "moment";

export const dateFormat = (date) => {
  var oldDate = new Date(),
    momentObj = moment(date).utc(),
    newDate = momentObj.add(2, "hours").format("Do MMM YYYY");

  return newDate;
};

export const formattedDate = (date) => {
    let dateObj = new Date(date);

    let day = dateObj.getUTCDate();
    let month = dateObj.toLocaleString('default', { month: 'long' });
    let year = dateObj.getUTCFullYear();

    let formattedDate = `${day} ${month} ${year}`;

  return formattedDate;
}

export const timeFormat = (date) => {
  var oldDate = new Date(),
    momentObj = moment(date).utc(),
    time = momentObj.add(2, "hours").format("HH:mm:ss");

  return time;
};

export const dateTimeFormat = (date) => {
  var oldDate = new Date(),
    momentObj = moment(date).utc(),
    newDate = momentObj.add(2, "hours").format("D MMM YYYY, hh:mm:ss ");

  return newDate;
};

export const dateTimeMoment = (date) => {
  var oldDate = new Date(),
    momentObj = moment(date).utc(),
    dateTime = momentObj.add(2, "hours").format("YYYY-MM-DD HH:mm:ss");

  return dateTime;
};

export const formatePrice = (value) => {
  return value.toFixed(2);
};
