import { DatePicker, Input, notification, Select } from 'antd';
import { Chart } from 'chart.js';
import { connect } from "react-redux";
import React, { Component } from 'react'
import { withRouter } from 'react-router';
import { getCardReport } from '../../Graphs/Report/getCardReport';
import { getBalanceReport } from '../../Graphs/Report/getBalanceReport';
import { getDistributorList } from '../../Graphs/distributor/getDistributorList';

import moment from 'moment';
import { alertError } from '../../utils/alert';
const {  RangePicker } = DatePicker;

const options = [
    {
        value: '',
        label: 'All',
    },
    {
        value: 'approved',
        label: 'approved',
    },
    {
        value: 'un_approved',
        label: 'Un Approved',
    },
    {
        value: 'instant_balance',
        label: 'Instant Balance',
    },
    {
        value: 'not_instant_balance',
        label: 'Not Instant Balance',
    },
]
class BalanceReportGraph extends Component {

    constructor(props) {
        super(props);
        this.state = {
          startDate : this.getStartDate(),
          endDate : this.getEndDate(),
          filter:"",
          distributorId:"",
          wholeData : [],
          distributorOptions:[]
      };
    }
    
    
    componentDidMount(){
        const {startDate,endDate,filter } = this.state;
        this.getGraph(startDate,endDate,filter);
        this.getDistributorDataList();
    }

    getStartDate(){
      const date = new Date();
      date.setDate(date.getDate() - 7)
        return date;
    }
    getEndDate(){
      const date = new Date();
      date.setDate(date.getDate() - 1)
        return date;
    }

    async getGraphData(startDate,endDate,filter,distributorId ){
      try {
        const {authToken,loginUserData} = this.props;

        let addCardReportData = await getBalanceReport(
          authToken, 
          startDate,
          endDate,
          filter,
          distributorId,
          );
          if (addCardReportData.status == 200) {
            let count = {};
            let countArray = {} ;
            let approved = {};
            let instantBalance = {};
            let unApproved ={};
            let notInstantBalance={};
            if (!addCardReportData) return null;

            addCardReportData.data.forEach(element => {

              let momentObj = moment(new Date(element.createdAt)).utc();
              let newDate = momentObj.format("DD MMM YYYY");

              if(filter == ""){  
                                          
                if(element.is_approved ){
                    approved[newDate] = (approved[newDate] || 0) + 1;
                }else{
                    unApproved[newDate] = (unApproved[newDate] || 0) + 1
                }
                
                if(element.is_instant_balance){
                    instantBalance[newDate] = (instantBalance[newDate] || 0) + 1;
                }else{
                    notInstantBalance[newDate] = (instantBalance[newDate] || 0) + 1
                }

              }
              else{
                if(newDate){
                  count[newDate] = (count[newDate] || 0) + 1;
                }
              }
            });
            countArray["approved"] = approved;
            countArray["instantBalance"] = instantBalance;
            countArray["unApproved"] = unApproved;
            countArray["notInstantBalance"] = notInstantBalance;
            
          return filter == "" ? countArray : count;

          }    
      } catch (error) {
          console.log(error)
      }

    }

    getDistributorDataList = async () => {
      try {
          const { authToken  } = this.props;
          let page = 0;
          let limit = 10;
          let distributorData = await getDistributorList(authToken, page, limit);

          if (distributorData.status == 200) {
            let distributor = distributorData.data.map((item)=>{
              return({
                  value: item.first_name +" "+ item.last_name,
                  label: item.first_name +" "+ item.last_name,
                  id : item.id
              })
            })
            this.setState({
              distributorOptions : [{value :"",label:"All",id:""},...distributor]
            })
            
          } else if (distributorData.status == 401) {
              console.log("I amm 401");
          } else {
              throw (distributorData && distributorData.message) ? distributorData.message : "Error";
          }
      } catch (e) {
          alertError("REPORT",e);
         
      }
  }

    async getGraph(startDate,endDate,filter,distributorId){

      let data = await this.getGraphData(startDate,endDate,filter,distributorId);
      
      let combinedStats = {
        ...data.approved,
        ...data.instantBalance,
        ...data.unApproved,
        ...data.notInstantBalance,
      }

      var keys  = Object.keys(combinedStats).map((key)=>{
          return key
      })

      keys.sort(function(a,b){
        return new Date(a) - new Date(b);
      });

        new Chart(
            document.getElementById('acquisitions'),
            {
              type: 'line',
              data: {
                labels :filter == "" ? 
                 keys:
                  Object.keys(data).sort(function(a,b){
                    return new Date(a) - new Date(b);
                  }),

                options: {
                  responsive: true,
                  plugins: {
                    legend: {
                      position: 'top',
                    },
                    title: {
                      display: true,
                      text: 'Chart.js Line Chart'
                    }
                  },
                  scales: {
                    xAxes: [{
                      type: 'date',
                    }],
                  }
                },
                datasets:filter == "" ?
                [
                  {
                    label:'Approved',
                    data:keys.map((key)=>{
                      if(data.approved[key]){
                          return {x: key,y: data.approved[key] }
                      }else{
                          return {x: key,y: 0 }
                      }
                    }),
                    fill: false,
                    borderColor: 'green',
                    tension: 0.1,
                  },
                  {
                    label:'Pending',
                    data : keys.map((key)=>{
                      if(data.unApproved[key]){
                          return {x: key,y: data.unApproved[key] }
                      }else{
                          return {x: key,y: 0 }
                      }
                  }),
                    fill: false,
                    borderColor:"red",
                    tension: 0.1,
                  },
                  {
                    label:'Instant Balance',
                    data : keys.map((key)=>{
                      if(data.instantBalance[key]){
                          return {x: key,y: data.instantBalance[key] }
                      }else{
                          return {x: key,y: 0 }
                      }
                  }),
                    fill: false,
                    borderColor:"blue",
                    tension: 0.1,
                  },
                  {
                    label:'Recharge Balance',
                    data : keys.map((key)=>{
                      if(data.notInstantBalance[key]){
                          return {x: key,y: data.notInstantBalance[key] }
                      }else{
                          return {x: key,y: 0 }
                      }
                  }),
                    fill: false,
                    borderColor:"orange",
                    tension: 0.1,
                  }
                  
                ]:[
                  {
                    label:filter == "approved" ? 'Approved' : filter == "instant_balance" ? "Instant balance": filter == "un_approved" ? "Pending" : 'Recharge Balance',
                    data: Object.values(data),
                    fill: false,
                    borderColor:filter == "approved" ? 'green' : filter == "instant_balance" ? "blue": filter == "un_approved" ? "red" : 'orange',
                    tension: 0.1,
                  },
                ]
              }
            }
          );
    } 

    onChangeDate = (date) => {
      let startDate = date[0].format("YYYY-MM-DD HH:mm:ss");
      let endDate = date[1].format("YYYY-MM-DD HH:mm:ss");
      
      if (!date.length) {
        this.setState({
          startDate: null,
          endDate: null
        });
      }
      this.setState({
        startDate: startDate,
        endDate: endDate
      });

      this.getGraph(startDate,endDate,this.state.filter,this.state.distributorId);

    };

    handleChange = (value) => {
      const {startDate,endDate ,distributorId} = this.state;
      this.setState({
        filter: value,
      });
      this.getGraph(startDate,endDate,value,distributorId);

    };
     
    handleChangeDistributor = (value) => {
      let id = this.state.distributorOptions.find((i)=> i.value == value).id;
      const {startDate,endDate,filter } = this.state;
      this.setState({
        distributorId: id,
      });
      this.getGraph(startDate,endDate,filter,id);
    };

    render() {
      return (
          <>
             <div style={{ float : "left", width : "100%", height:"32px", marginBottom:"25px", display:"flex" }}>
                  <RangePicker
                      format="DD-MM-YYYY"
                      onChange={this.onChangeDate}
                      disabledDate={(current) => {
                        return moment().add(0, 'days')  <= current 
                      }}
                  />
                
                  <div style={{ marginLeft: "15px", marginBottom: "15px" }}>
                    <Select
                        defaultValue="All"
                        style={{
                            width: " 150px",
                        }}
                        onChange={this.handleChange}
                        options={options}
                    />
                  </div>

                  <div style={{ marginLeft: "15px", marginBottom: "15px" }}>
                    <Select
                        showSearch
                        defaultValue="All"
                        style={{
                            width: " 150px",
                        }}
                        onChange={this.handleChangeDistributor}
                        options={this.state.distributorOptions}
   
                    />
                  </div>
              </div>
            <div style={{width:"99%"}}><canvas id="acquisitions" ></canvas></div>
          </>
      )
    }
  }


  const mapStateToProps = (state) => ({
    authToken: state.auth.authToken,
    loginUserData: state.auth.loginUserData,
  });
  
  const mapDispatchToProps = (dispatch) => { 
    return {
      setLoginFlag: (flag) => {
        dispatch({
          type: "SET_LOGIN_FLAG",
          flag: flag,
        });
      },
    }; 
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BalanceReportGraph));
  