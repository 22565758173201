import { Button, Divider } from "antd";
import * as React from "react";
import { Layout, Input, Select } from "antd";
import { Formik, Field } from "formik";
import styled from "styled-components";
import { StyleSelect } from "../../assets/css/style";
import { getCityListAPI } from "../../Graphs/Master/City/getCityListAPI";
import { alertError } from "../../utils/alert";
import { errorMessages } from "../../utils/validationMessages";
import {
  emailValidation,
  mobileLengthValidation,
  mobileValidation,
} from "../../utils/validations";

const { Content } = Layout;
const Option = Select.Option;
const { TextArea } = Input;
var amenities = [];


const StyleDivider = styled(Divider)`
  &.ant-divider-horizontal {
    margin: 14px 0px;
  }
`;

export class EditForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTime = this.handleTime.bind(this);
    this.handleCloseTime = this.handleCloseTime.bind(this);
    this.handleDetailsModalclose = this.handleDetailsModalclose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      locationList: [],
      recordSelectedtoView: null,
      data: [],
      view: false,
      index: null,
      isLoading: false,
      popup: false,
      checked: false,
      item: null,
      filters: null,
      visible: false,
      inputVisible: false,
      inputValue: "",
      time: "",
      closeTime: "",
      closeTimeString: "",
      timeString: "",
      roleType: "1",
      cityData: [],
    };
  }

  input;

  componentDidMount() {
    this.getCityList();
  }
  
  async getCityList() {
    try {
      let getCityList = await getCityListAPI(this.props.authToken);

      if (getCityList.status == 200) {
        let temp = getCityList.data.map((i) => ({
          ...i,
          label: i.name,
          value: i.name,
        }));

        this.setState({
          cityData: [
            ...this.state.cityData,
            { label: "--- Select City ---", value: "Select" },
            ...temp,
          ],
        });
      } else {
        throw getCityList.message ? getCityList.message : "Error";
      }
    } catch (error) {
      alertError("User", error);

      return -1;
    }
  }

  onChange(time, timeString) {
    console.log(time, timeString);
  }

  handleChange(value) {
    amenities = value;
  }

  validateForm(values) {
    const errors = {};

    if (!values.first_name) {
      errors.first_name = errorMessages.first_name_required;
    }
    if (!values.last_name) {
      errors.last_name = errorMessages.last_name_required;
    }

    if (!values.phone) {
      errors.phone = errorMessages.mobile_required;
    } else if (mobileLengthValidation(values.phone)) {
      errors.phone = errorMessages.mobile_length_validation;
    } else if (mobileValidation(values.phone)) {
      errors.phone = errorMessages.mobile_validation;
    }

    if (!values.referal_code) {
      errors.referal_code = "Referal code is required";
    }

    if (!values.shop_name) {
      errors.shop_name = "Shop name is required";
    }

    return errors;
  }

  handleSubmit(values, action) {
    const result = this.props.onSubmit(values, this.props.data.id);

    if (result) {
      this.props.handleCancel();

      this.setState({
        visible: false,
      });
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleTime(e, s) {
    this.setState({
      time: e,
      timeString: s,
    });
  }

  handleCloseTime(e, s) {
    this.setState({
      closeTime: e,
      closeTimeString: s,
    });
  }

  handleDetailsModalclose(item) {
    this.setState({
      view: false,
      lat: item.lat,
      long: item.lng,
    });
  }


  render() {
    return (
      <div>
        <Content style={{ background: "#fff", marginLeft: "10px" }}>
          <Formik
            initialValues={{
              first_name: this.props.data.first_name,
              last_name: this.props.data.last_name,
              email: this.props.data.email,
              phone: this.props.data.phone,
              referal_code: this.props.data.referal_code,
              shop_name: this.props.data.shop_name,
              address: this.props.data.address_line1,
              city:
                this.props.data?.user_city != null
                  ? this.props.data?.user_city?.name
                  : "",
              cityId:
                this.props.data?.city != null ? this.props.data?.city : "",
            }}
            validate={this.validateForm}
            onSubmit={this.handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
            }) => {
              return (
                <div>
                  <div className="formik-field_wrap">
                    <div className="formik-field-left">
                      First Name
                      <Input
                        id="first_name"
                        placeholder="First Name"
                        value={values.first_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.first_name && touched.first_name ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {errors.first_name}
                        </p>
                      ) : null}
                    </div>
                    <div className="formik-field-right">
                      Last Name
                      <Input
                        id="last_name"
                        placeholder="Last Name"
                        value={values.last_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.last_name && touched.last_name ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {errors.last_name}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="formik-field_wrap">
                    <div className="formik-field-left">
                      Phone
                      <Input
                        id="phone"
                        placeholder="Phone"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.phone && touched.phone ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {errors.phone}
                        </p>
                      ) : null}
                    </div>
                    <div className="formik-field-right">
                      Email
                      <Input
                        id="email"
                        placeholder="Email"
                        disabled={true}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.email && touched.email ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {errors.email}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="formik-field_wrap">
                    <div className="formik-field-left">
                      Referal code
                      <Input
                        id="referal_code"
                        placeholder="Referal Code"
                        disabled={true}
                        value={values.referal_code}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.referal_code && touched.referal_code ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {errors.referal_code}
                        </p>
                      ) : null}
                    </div>
                    <div className="formik-field-right">
                      Shop Name
                      <Input
                        id="shop_name"
                        placeholder="Shop Name"
                        value={values.shop_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.shop_name && touched.shop_name ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {errors.shop_name}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="formik-field_wrap">
                    <div className="formik-field-left">
                      Address
                      <Input
                        id="address"
                        placeholder="Address"
                        value={values.address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.address && touched.address ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {errors.address}
                        </p>
                      ) : null}
                    </div>

                    <div className="formik-field-right">
                      City
                      <Field       
                        name="city"
                        render={({ field }) => (
                          <StyleSelect
                            style={{ width: "100%"}}
                            placeholder="Select City"
                            name="city"
                            defaultValue={values.city}
                            onBlur={() => setFieldTouched("city", true)}
                            value={values.city}
                            onSelect={(value, option) => {
                              setFieldValue("city", value);
                              setFieldValue("cityId", option.id);
                            }}
                            options={this.state.cityData}
                            {...field}
                          ></StyleSelect>
                        )}
                      />
                    </div>
                  </div>

                  <Button
                    // type="primary"
                    style={{
                      marginTop: "19px",
                      background: "#5C6AC4",
                      color: "#fff",
                    }}
                    onClick={handleSubmit}
                  >
                    Update
                  </Button>
                </div>
              );
            }}
          </Formik>
        </Content>
      </div>
    );
  }
}
