import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { BarChart2, DollarSign } from "react-feather";
import { withRouter } from "react-router";
import Title from "../common/Title";
import AddSalesModal from "./AddSalesModal";
import AddButton from "../common/AddButton";
import { createSalesUser } from "../../Graphs/sales/CreateSalesUser";
import { Button, DatePicker, notification, Spin } from "antd";
import { getSalesUserList } from "../../Graphs/sales/getSalesUserList";
import SalesTable from "./SalesTable";
import { updateSalesUser } from "../../Graphs/sales/UpdateSalesUser";
import { deleteSalesUser } from "../../Graphs/sales/DeleteSalesUser";
import { AddUserModal } from "../User/AddUserModal";
import { createUser } from "../../Graphs/User/createUser";
import { getCityListAPI } from "../../Graphs/Master/City/getCityListAPI";
import { alertError } from "../../utils/alert";

class Sales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addModalVisible: false,
      addUserModalVisible: false,
      isLoading: false,
      closable: true,
      salesNameList: [],
      cityData: [{ label: "--- Select City ---", value: "Select" }],
      primaryColor: "#4466f2",
      salesList: [],
      totalsales: 0,
      totalOutstandingBalance: 0,
      startDate: "",
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
    };
    this.createSalesUser = this.createSalesUser.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.updateSales = this.updateSales.bind(this);
    this.deleteSales = this.deleteSales.bind(this);
    this.createAndAssignUserToSales =
      this.createAndAssignUserToSales.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { setRouteName } = this.props;
    let page = 0;
    let limit = 10;
    this.getSalesDataList(page, limit);
    this.getCityList();
    setRouteName("SALES");
  }

  getSalesDataList = async (page, limit) => {
    try {
      const { authToken, history, setSalesData, setTotalSalesData, sale } =
        this.props;
      let salesData = await getSalesUserList(authToken, page, limit);

      this.setState({
        isLoading: true,
      });
      if (salesData.status == 200) {
        await setSalesData(salesData.data);
        await setTotalSalesData(salesData.total_user);

        this.setState({
          salesList: salesData.data,
          totalsales: salesData.total_user,
          totalOutstandingBalance: salesData.total_outstanding_balance,
          isLoading: false,
        });

        this.getDropdownSalesDataList();
      } else if (salesData.status == 401) {
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw salesData && salesData.message ? salesData.message : "Error";
      }
    } catch (e) {
      alertError("SALES", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  getDropdownSalesDataList = async () => {
    try {
      const { authToken } = this.props;
      let salesData = await getSalesUserList(authToken, 0, 100000);
      if (salesData.status == 200) {
        this.setState({
          salesNameList: salesData.data.map((i) => ({
            ...i,
            label: i.first_name + " " + i.last_name,
            value: i.first_name + " " + i.last_name,
          })),
        });
      }
    } catch (e) {
      alertError("SALES", e);
    }
  };

  async getLimitedData(pagination) {
    const page = pagination.current * pagination.pageSize - pagination.pageSize;
    const limit = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: page,
        limitNumber: limit,
      },
      async () => {
        this.getSalesDataList(page, limit);
      }
    );
  }

  handleTableChange(pagination, filters) {
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedData(pagination)
    );
  }

  handleOk = (e) => {
    this.setState({
      addModalVisible: false,
      addUserModalVisible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      addModalVisible: false,
      addUserModalVisible: false,
    });
  };

  openAddModal = () => {
    this.setState({
      addModalVisible: true,
    });
  };

  openAddUserModal = () => {
    this.setState({
      addUserModalVisible: true,
    });
  };

  async createSalesUser(value) {
    try {
      this.setState({
        isLoading: true,
      });

      let createObject = value;
      let salesData = await createSalesUser(this.props.authToken, createObject);

      if (salesData.status == 200) {
        this.setState({
          isLoading: true,
        });

        notification["success"]({
          message: "Sales User",
          description: salesData.message,
        });
        this.handleOk();
        this.getSalesDataList(0, 10);
        return 1;
      } else {
        throw salesData.message;
      }
    } catch (error) {
      // this.setState({
      //     isLoading: false,
      // });
      alertError("SALES", error);

      return -1;
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  async updateSales(value, id) {
    try {
      let updatedSalesData = await updateSalesUser(
        this.props.authToken,
        value,
        id
      );

      if (updatedSalesData.status == 200) {
        notification["success"]({
          message: "Sales User",
          description: updatedSalesData.message,
        });
        this.handleOk();
        this.getSalesDataList(0, 10);

        return 1;
      } else {
        throw updatedSalesData.message;
      }
    } catch (error) {
      alertError("SALES", error);
      return -1;
    }
  }

  async deleteSales(item) {
    try {
      let deleteData = await deleteSalesUser(this.props.authToken, item.id);

      if (deleteData.status == 200) {
        notification["success"]({
          message: "Sales User",
          description: deleteData.message,
        });
        this.getSalesDataList(0, 10);

        return 1;
      } else {
        throw deleteData.message;
      }
    } catch (error) {
      alertError("SALES", error);

      return -1;
    }
  }

  createAndAssignUserToSales = async (values) => {
    try {
      const { authToken } = this.props;
      this.setState({
        isLoading: true,
      });
      let addClientData = await createUser(authToken, values);
      if (addClientData.status == 200) {
        notification["success"]({
          message: "Add User",
          description: "User added successFully",
        });
        this.getSalesDataList(0, 10);
      } else if (addClientData.status == 500) {
        alertError("SALES", addClientData.message);
      } else {
        throw addClientData.message ? addClientData.message : "Error";
      }
    } catch (e) {
      alertError("SALES", e);
    }
  };

  async getCityList() {
    try {
      let getSalesData = await getCityListAPI(this.props.authToken);

      if (getSalesData.status == 200) {
        let temp = getSalesData.data.map((i) => ({
          ...i,
          label: i.name,
          value: i.name,
        }));
        this.setState({
          cityData: [...this.state.cityData, ...temp],
        });
      } else {
        throw getSalesData.message ? getSalesData.message : "Error";
      }
    } catch (error) {
      alertError("SALES", error);
      return -1;
    }
  }

  render() {
    const { authToken, loginUserData } = this.props;
    const { accessPermission } = loginUserData;
    if (!this.props.sale) return null;

    return (
      <Fragment>
        <Title parent="Sales" title="Sales" icon={<BarChart2 />} url="/sale" />
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            width: "100%",
            marginBottom: "20px",
            paddingLeft: "14px",
            paddingRight: "55px",
            justifyContent: "spaceBetween",
          }}
        >
          <div style={{ cursor: "pointer", width: "100%" }}>
            {
              <div style={{ display: "flex", gap: 10 }}>
                <AddButton
                  color={this.state.primaryColor}
                  show={this.openAddModal}
                >
                  {" "}
                  Add Salesman{" "}
                </AddButton>
                <AddUserModal
                  refx={(e) => (this.modalRef = e)}
                  primaryColor={this.state.primaryColor}
                  authToken={this.props.authToken}
                  salesmanData={this.state.salesNameList}
                  cityData={this.state.cityData}
                  onSubmit={this.createAndAssignUserToSales}
                />
              </div>
            }
          </div>
          <div
            style={{
              cursor: "pointer",
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {"Total Outstanding Balance From Salesman: " +
              this.state.totalOutstandingBalance.toFixed(2) +
              " LYD"}
          </div>
        </div>

        <div className="container-fluid">
          {this.state.isLoading ? (
            <div style={{ marginLeft: "20px" }}>
              <Spin
                size="large"
                style={{ marginLeft: "480px", marginTop: "130px" }}
              />
            </div>
          ) : (
            <div>
              <SalesTable
                // accessPermission={accessPermission}
                loginUserData={loginUserData}
                salesList={
                  this.props.sale?.length > 0
                    ? this.props.sale
                    : this.state.salesList
                }
                totalsales={
                  this.props.totalSales
                    ? this.props.totalSales
                    : this.state.totalsales
                }
                pagination={this.state.pagination}
                handleTableChange={this.handleTableChange}
                primaryColor={this.state.primaryColor}
                authToken={authToken}
                isLoading={this.state.isLoading}
                updateSalesUser={this.updateSales}
                delete={this.deleteSales}
                reload={() => {
                  this.getSalesDataList(0, 10);
                }}
              />
            </div>
          )}
        </div>
        {this.state.addModalVisible && (
          <AddSalesModal
            visible={this.state.addModalVisible}
            handleCancel={this.handleCancel}
            handleOk={this.handleOk}
            onClose={this.handleCancel}
            isLoading={this.state.isLoading}
            createSalesUser={this.createSalesUser}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
  sale: state.sales.salesData,
  totalSales: state.sales.totalSales,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setSalesData: (salesData) => {
      dispatch({
        type: "SET_SALES_DATA",
        salesData: salesData,
      });
    },
    setTotalSalesData: (totalSales) => {
      dispatch({
        type: "SET_SALES_DATA_TOTAL",
        totalSales: totalSales,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sales));
