import React, { Fragment, Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button, Select, Spin, notification } from "antd";
import Title from "../User/Title";
import { Pocket } from "react-feather";
import { alertError } from "../../utils/alert";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ExpensesTable from "./ExpensesTable";
import { getExpensesListAPI } from "../../Graphs/expenses/GetExpensesList";
import AddEditExpenses from "./AddEditExpensesModel";
import { addExpensesAPI } from "../../Graphs/expenses/AddExpenses";
import { deleteExpensesAPI } from "../../Graphs/expenses/DeleteExpenses";

class Expenses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      expensesList: [],
      expensesListSize: 0,
      primaryColor: "#4466f2",
      addExpensesModal: false,
      pagination: {
        pageSize: 10,
        current: 1,
      },
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.deleteExpenses = this.deleteExpenses.bind(this);
  }

  async componentDidMount() {
    const { setRouteName } = this.props;
    this.getExpensesList(0, 10, "");
    setRouteName("EXPENSES");
  }

  async getLimitedCards(pagination) {
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;
    this.getExpensesList(start, end, "");
  }

  handleTableChange(pagination, filters) {
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedCards(pagination)
    );
  }

  addSubmit = async (values) => {
    try {
      this.setState({
        isLoading: true,
      });

      const payload = {
        expenses_date: values.date.format("YYYY-MM-DD HH:mm:ss"),
        amount: values.amount,
        description: values.description,
      };

      let expensesData = await addExpensesAPI(this.props.authToken, payload);

      if (expensesData.status === 200) {
        this.setState({
          isLoading: false,
        });

        notification["success"]({
          message: "Expenses",
          description: "Successfully Added",
        });
        this.getExpensesList(0, 10, "");
        return 1;
      } else {
        throw expensesData.message ? expensesData.message : "Error";
      }
    } catch (error) {
      this.setState({
        isLoading: false,
      });
      alertError("Expenses", error);
      return -1;
    }
  };

  getExpensesList = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setExpensesData,
        setTotalExpensesData,
        expenses,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      const expensesListData = await getExpensesListAPI(authToken, start, end);

      if (expensesListData.status == 200) {
        await setExpensesData(expensesListData.data);
        await setTotalExpensesData(expensesListData.total_expenses);

        this.setState({
          isLoading: false,
          expensesList: expensesListData.data,
          expensesListSize: expensesListData.count,
        });
      } else if (expensesListData.status == 401) {
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw expensesListData && expensesListData.message
          ? expensesListData.message
          : "Error";
      }
    } catch (e) {
      alertError("Expenses", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  async deleteExpenses(item) {
    try {
      let deleteData = await deleteExpensesAPI(this.props.authToken, item.id);

      if (deleteData.status == 200) {
        notification["success"]({
          message: "Expenses",
          description: deleteData.message,
        });
        this.getExpensesList(0, 10, "");
        return 1;
      } else {
        throw deleteData.message;
      }
    } catch (error) {
      alertError("Expenses", error);

      return -1;
    }
  }

  handleCancel = () => {
    this.setState({
      addExpensesModal: false,
    });
  };

  render() {
    const { authToken, loginUserData } = this.props;
    const { isLoading, pagination } = this.state;
    const permission = JSON.parse(this.props.loginUserData.access_permission);

    return (
      <Fragment>
        <Title
          parent="Expenses"
          title="Expenses"
          icon={<Pocket />}
          url="/expenses"
        />

        <div
          style={{
            marginTop: "20px",
            display: "inline-block",
            width: "100%",
            marginBottom: "20px",
            paddingLeft: "14px",
            paddingRight: "55px",
          }}
        >
          <div style={{ float: "right", cursor: "pointer", width: "100%" }}>
            <Button
              style={{
                backgroundColor: "rgb(57, 73, 171)",
                color: "white",
                borderRadius: "50px",
                padding: "8px 15px 30px 15px",
                width: "140px",
              }}
              onClick={() => {
                this.setState({
                  addExpensesModal: true,
                });
              }}
            >
              Add Expenses
            </Button>

            {this.state.addExpensesModal &&
              (loginUserData.role === "Admin" || permission.expenses.edit) && (
                <AddEditExpenses
                  visible={this.state.addExpensesModal}
                  close={this.handleCancel}
                  authToken={this.props.authToken}
                  onSubmit={this.addSubmit}
                />
              )}
          </div>
        </div>

        <div className="container-fluid">
          {isLoading ? (
            <div style={{ marginLeft: "20px" }}>
              <Spin
                size="large"
                style={{ marginLeft: "480px", marginTop: "130px" }}
              />
            </div>
          ) : (
            <div>
              <ExpensesTable
                expensesList={
                  this.props.expense_data?.length > 0
                    ? this.props.expense_data
                    : this.state.expensesList
                }
                expensesListSize={
                  this.props.total_expences
                    ? this.props.total_expences
                    : this.state.expensesListSize
                }
                isLoading={isLoading}
                getExpensesList={this.getExpensesList}
                pagination={pagination}
                handleTableChange={this.handleTableChange}
                delete={this.deleteExpenses}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
  expense_data: state.expenses.expensesData,
  total_expences: state.expenses.totalExpenses,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setExpensesData: (expensesData) => {
      dispatch({
        type: "SET_EXPENSES_DATA",
        expensesData: expensesData,
      });
    },
    setTotalExpensesData: (totalExpenses) => {
      dispatch({
        type: "SET_EXPENSES_DATA_TOTAL",
        totalExpenses: totalExpenses,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Expenses));
