import { Button, DatePicker, notification, Select } from "antd";
import { Field, Formik } from "formik";
import moment from "moment";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { getSalesUserOutStandingBalance } from "../../Graphs/Report/getSalesUserOutStandingBalance";
import { getSalesUserList } from "../../Graphs/sales/getSalesUserList";
import { alertError } from "../../utils/alert";
import TableWrap from "../common/Table";
import { connect } from "react-redux";

class UserOutStandingBalanceReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      salesData: [{ label: "All", value: "" }],
      selectedSalesValue: "",
      isLoading: false,
    };
  }

  handleSelectSales(value) {
    this.setState({
      selectedSalesValue: value,
    });
  }

  async componentDidMount() {
    this.salesUserList();
  }

  salesUserList = async () => {
    try {
      this.getUserOutStandingList("");
      let page = 0;
      let limit = 20;
      let salesList = await getSalesUserList(this.props.authToken, page, limit);
      if (salesList.status == 200) {
        let temp = salesList.data.map((i) => ({
          ...i,
          label: i.first_name + " " + i.last_name,
          value: i.id,
        }));
        this.setState({
          salesData: [...this.state.salesData, ...temp],
        });
      }
    } catch (error) {
      console.log("error");
    }
  };

  getUserOutStandingList = async (salesmanId) => {
    try {
      const { authToken } = this.props;
      let userOutStandingBalance = await getSalesUserOutStandingBalance(
        authToken,
        salesmanId ? salesmanId : ""
      );

      this.setState({
        isLoading: true,
      });
      if (userOutStandingBalance.status == 200) {
        this.setState({
          data: userOutStandingBalance.data,
        });
      }

      //   throw userOutStandingBalance.message
      //     ? userOutStandingBalance.message
      //     : "Error";
    } catch (e) {
      alertError("REPORT", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  columns = [
    {
      title: "Sr. No.",
      width: 100,
      key: "serial_number",
      render: (text, item, index) => {
        return (
          <div key={"sno" + index}>
            <span>{++index}</span>
          </div>
        );
      },
    },
    {
      title: "User",
      width: 200,
      key: "name",
      render: (text, item, index) => {
        return (
          <div key={"name" + index}>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column" style={{ marginTop: "5px" }}>
                {item.first_name + " " + item.last_name}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Shop Name",
      width: 200,
      key: "shop name",
      render: (text, item, index) => {
        return (
          <div key={"shopname" + index}>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column" style={{ marginTop: "5px" }}>
                {item.shop_name}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Outstanding Balance",
      width: 200,
      key: "outstanding balance",
      render: (text, item, index) => {
        return (
          <div key={"name" + index}>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column" style={{ marginTop: "5px" }}>
                {item.outstanding_balance.toFixed(2)}
              </div>
            </div>
          </div>
        );
      },
    },
  ];
  render() {
    return (
      <>
        <Formik
          initialValues={{
            // date: "",
            selectSales: "",
          }}
          onSubmit={() => {
            this.getUserOutStandingList(
              this.state.selectedSalesValue
                ? this.state.selectedSalesValue
                : ""
            );
          }}
        >
          {({ values, handleSubmit, setFieldTouched, setFieldValue }) => {
            return (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  width: "100%",
                  marginBottom: "20px",
                  // paddingLeft: "14px",
                  paddingRight: "55px",
                  justifyContent: "left",
                }}
              >
                <div style={{ cursor: "pointer" }}>
                  <Field
                    name="selectSales"
                    render={({ field }) => (
                      <Select
                        style={{ width: "200px" }}
                        placeholder="Select Sales"
                        {...field}
                        onChange={(value) => {
                          setFieldValue("selectSales", value);
                          this.handleSelectSales(value);
                        }}
                        onBlur={() => setFieldTouched("selectSales", true)}
                        onSelect={(value, option) =>
                          this.handleSelectSales(value, option)
                        }
                        options={this.state.salesData}
                      ></Select>
                    )}
                  />
                </div>

                <div style={{ cursor: "pointer", width: "100%" }}>
                  <Button
                    style={{ background: "#5C6AC4", color: "#fff" }}
                    color={this.state.primaryColor}
                    onClick={handleSubmit}
                    disabled={this.state.startDate == ""}
                  >
                    Filter
                  </Button>
                </div>
              </div>
            );
          }}
        </Formik>

        <TableWrap
          data={this.state.data}
          columns={this.columns}
          isLoading={this.state.isLoading}
          total={this.state.data.length}
          pagination={false}
          // handleTableChange={this.props.handleTableChange}
          scroll={{ x: 1000 }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
});

export default connect(mapStateToProps)(
  withRouter(UserOutStandingBalanceReport)
);
