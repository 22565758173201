import * as React from "react";
import { Modal, Spin, Input, Button, notification } from "antd";
import { deleteRedeemCard } from "../../Graphs/Card/deleteRedeemCard";
import { alertError } from "../../utils/alert";

export class DeleteRedeemModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: [],
      recordDetails: {},
      isLoading: false, // Fixme this should be true
      closable: true,
      searchText: "",
    };
  }

  componentDidMount() {
    if (this.props.refx) {
      this.props.refx(this);
    }
  }

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.hide();
  };

  show() {
    this.setState({
      visible: true,
    });
  }

  hide() {
    this.setState({
      visible: false,
    });
    this.props.onClose();
  }

  onChangeText = (e) => {
    this.setState({
      searchText: e.target.value,
    });
  };

  deleteRedeemCode = async () => {
    try {
      const { searchText } = this.state;
      const { authToken } = this.props;

      if (!searchText) {
        alertError('Redeem',"Please enter Redeem Code");
        return;
      }

      this.setState({
        isLoading: true,
      });

      let statusData = await deleteRedeemCard(authToken, searchText);
      if (statusData.status == 200) {
        notification["success"]({
          message: "Delete Redeem",
          description: "Successfully deleted",
        });
        this.handleCancel();
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (e) {
      alertError('Redeem',e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const { searchText } = this.state;

    return (
      <Modal
        width="600px"
        closable={this.state.closable}
        open={this.state.visible}
        title="Delete Redeem Code"
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={null}
        destroyOnClose={true}
      >
        {this.state.isLoading ? (
          <div style={{ justifyContent: "center" }}>
            <Spin />
          </div>
        ) : (
          <div>
            <Input
              id="redeem"
              placeholder="Redeem Code"
              value={searchText}
              onChange={(e) => this.onChangeText(e)}
            />

            <div style={{ marginTop: "15px" }}>
              <Button type="primary" onClick={() => this.deleteRedeemCode()}>
                Delete
              </Button>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}
