import {
    SET_LIST_COUNTRY_DATA, 
    SET_LIST_COUNTRY_DATA_TOTAL, 
    SET_LIST_CATEGORY_DATA, 
    SET_LIST_CATEGORY_DATA_TOTAL ,
    SET_LIST_TYPE_DATA,
    SET_LIST_TYPE_DATA_TOTAL,
    SET_LIST_COIN_DATA,
    SET_LIST_COIN_DATA_TOTAL,
    SET_LIST_CITY_DATA,
    SET_LIST_CITY_DATA_TOTAL,
    SET_LIST_CURRENCY_DATA,
    SET_LIST_CURRENCY_DATA_TOTAL
} from "../constant/actionTypes";

const initial_state = {
    listCountryData : null,
    listCountryDataTotal: 0,
    listCategoryData : null,
    listCategoryDataTotal: 0,
    listTypeData : null,
    listTypeDataTotal: 0,
    listCoinData : null,
    listCoinDataTotal: 0,
    listCityData : null,
    listCityDataTotal: 0,
    listCurrencyData : null,
    listCurrencyDataTotal: 0,
};

export default (state = initial_state, action) => {
    switch (action.type) {

        case SET_LIST_COUNTRY_DATA:
            return { ...state, loading: false, listCountryData: action.listCountryData };

        case SET_LIST_COUNTRY_DATA_TOTAL:
            return { ...state, loading: false, listCountryDataTotal: action.listCountryDataTotal };

        case SET_LIST_CATEGORY_DATA:
            return { ...state, loading: false, listCategoryData: action.listCategoryData };

        case SET_LIST_CATEGORY_DATA_TOTAL:
            return { ...state, loading: false, listCategoryDataTotal: action.listCategoryDataTotal };

        case SET_LIST_TYPE_DATA:
            return { ...state, loading: false, listTypeData: action.listTypeData };

        case SET_LIST_TYPE_DATA_TOTAL:
            return { ...state, loading: false, listTypeDataTotal: action.listTypeDataTotal };
        
        case SET_LIST_COIN_DATA:
            return { ...state, loading: false, listCoinData: action.listCoinData };
    
        case SET_LIST_COIN_DATA_TOTAL:
            return { ...state, loading: false, listCoinDataTotal: action.listCoinDataTotal };

            case SET_LIST_CITY_DATA:
                return { ...state, loading: false, listCityData: action.listCityData };
        
            case SET_LIST_CITY_DATA_TOTAL:
                return { ...state, loading: false, listCityDataTotal: action.listCityDataTotal };    
        
        case SET_LIST_CURRENCY_DATA:
            return { ...state, loading: false, listCurrencyData: action.listCurrencyData };
        
        case SET_LIST_CURRENCY_DATA_TOTAL:
            return { ...state, loading: false, listCurrencyDataTotal: action.listCurrencyDataTotal };
            
        default: return { ...state };
    }
}
