import { ENDPOINT } from "../../network/ENDPOINT";
import { callApiToServer } from "../callApi";

export async function addCard(authtoken, cardObject) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let body = JSON.stringify({
      name: cardObject.name,
      description: cardObject.description,
      color: cardObject.color,
      icon: cardObject.icon,
      logo: cardObject.logo,
      image: cardObject.image,
      category_id: cardObject.category,
      redeem_code: cardObject.redeem_code,
      cost_amount: cardObject.cost_price,
      store_sell_price: cardObject.store_sell_price,
      ...(cardObject.amount && { amount: String(cardObject.amount) }),
      ...(cardObject.amount && { discount_amount: String(cardObject.amount) }),
      ...(cardObject.amount && { sell_amount: String(cardObject.amount) }),
      ...(cardObject.amount && {
        sell_discount_amount: String(cardObject.amount),
      }),

      is_paid: cardObject.is_paid,
      terms_and_condition: cardObject.terms_and_condition,
      validity: parseInt(cardObject.validity),
      start_date: cardObject.start_date,
      end_date: cardObject.end_date,
      allowed_coins: cardObject.allowed_coins,
      allowed_countries: cardObject.allowed_countries,
      instructions: cardObject.instructions,
      company_id: cardObject.company_id,
      api_card_id: cardObject.api_card_id,
      commission: cardObject.commission,

      like_card_id: cardObject.like_card_id,
      like_card_amount: cardObject.like_card_amount,

      arabic_name: cardObject.arabicName,

      mint_card_ean: cardObject.mint_card_ean,
      mint_card_amount: cardObject.mint_card_amount,
      mint_category_id: cardObject.mint_category_id,
      mint_brand_id: cardObject.mint_brand_id,
    });

    let endUrl = ENDPOINT.add_card;

    let responseData = await callApiToServer(body, header, "POST", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
