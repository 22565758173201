import { Button, Switch, Popconfirm, Tooltip, notification, Spin } from "antd";
import * as React from "react";
import TableWrap from "../common/Table";
import { withRouter } from "react-router";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import {
  dateFormat,
  dateTimeFormat,
  dateTimeMoment,
  formatePrice,
} from "../../utils/general";
import { editExpensesAPI } from "../../Graphs/expenses/EditExpenses";
import { alertError } from "../../utils/alert";
import AddEditExpenses from "./AddEditExpensesModel";

class ExpensesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
      expensesData: null,
    };
  }

  componentDidMount() {}

  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      width: "100px",
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index}</span>
          </div>
        );
      },
    },
    {
      title: "Description",
      key: "description",
      width: "300px",
      render: (text, item, index) => {
        return <div>{item.description}</div>;
      },
    },
    {
      title: "Amount",
      key: "amount",
      width: "100px",
      render: (text, item, index) => {
        return <div>{item.amount && formatePrice(item.amount)}</div>;
      },
    },
    {
      title: "Date",
      key: "date",
      width: "150px",
      render: (text, item, index) => {
        return (
          <div>{item.expenses_date && dateTimeFormat(item.expenses_date)}</div>
        );
      },
    },
    {
      title: "Actions",
      width: "100px",
      key: "action",
      render: (text, item, index) => {
        let permission = JSON.parse(this.props.loginUserData.access_permission);

        return (
          <div style={{ display: "flex", width: "100%" }}>
            {this.props.loginUserData.role == "Admin" ? (
              <div mr="5px" style={{ float: "left" }}>
                <Tooltip placement="bottom" title="Edit">
                  <Button
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => {
                      this.setState({
                        expensesData: item,
                      });
                    }}
                  />
                </Tooltip>
              </div>
            ) : (
              permission.expenses.edit == true && (
                <div mr="5px" style={{ float: "left" }}>
                  <Tooltip placement="bottom" title="Edit">
                    <Button
                      shape="circle"
                      icon={<EditOutlined />}
                      onClick={() => {
                        this.setState({
                          expensesData: item,
                        });
                      }}
                    />
                  </Tooltip>
                </div>
              )
            )}

            {this.props.loginUserData.role == "Admin" ? (
              <div
                ml="15px"
                mr="5px"
                style={{ float: "left", marginLeft: "10px" }}
              >
                <Tooltip placement="bottom" title="Delete">
                  <Popconfirm
                    onConfirm={() => this.props.delete(item)}
                    title="Are you sure you want to delete expenses？"
                  >
                    <Button shape="circle" icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Tooltip>
              </div>
            ) : (
              permission.expenses.delete == true && (
                <div
                  ml="15px"
                  mr="5px"
                  style={{ float: "left", marginLeft: "10px" }}
                >
                  <Tooltip placement="bottom" title="Delete">
                  <Popconfirm
                    onConfirm={() => this.props.delete(item)}
                    title="Are you sure you want to delete expenses？"
                  >
                    <Button shape="circle" icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Tooltip>
                </div>
              )
            )}
          </div>
        );
      },
    },
  ];

  editSubmit = async (values, expensesId) => {
    try {
      const { authToken, history, setLoginFlag } = this.props;

      this.setState({
        isLoading: true,
      });

      const payload = {
        ...values,
        expenses_date: values.date
          ? values.date.format("YYYY-MM-DD HH:mm:ss")
          : dateTimeMoment(values.expenses_date),
      };

      let expensesListData = await editExpensesAPI(
        authToken,
        payload,
        expensesId
      );
      if (expensesListData.status == 200) {
        notification["success"]({
          message: "Edit Expenses",
          description: "Successfully edited",
        });

        this.props.getExpensesList(0, 10, "");
      } else if (expensesListData.status == 401) {
        await setLoginFlag(false);

        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw expensesListData && expensesListData.message
          ? expensesListData.message
          : "Error";
      }
    } catch (e) {
      alertError("Expenses", e);
    }
  };

  handleDetailsModalclose = () => {
    this.setState({
      expensesData: null,
    });
  };

  render() {
    const { expensesList, expensesListSize, isLoading, pagination } =
      this.props;
    const { expensesData } = this.state;

    return (
      <div style={{ "overflow-x": "auto" }}>
        <TableWrap
          data={expensesList}
          columns={this.columns}
          isLoading={isLoading}
          total={expensesListSize}
          pagination={pagination}
          handleTableChange={this.props.handleTableChange}
          // scroll={{ x: 800 }}
        />
        {expensesData ? (
          <AddEditExpenses
            authToken={this.props.authToken}
            onSubmit={this.editSubmit}
            visible={expensesData}
            data={expensesData}
            close={this.handleDetailsModalclose.bind()}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ExpensesTable));
