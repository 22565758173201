import { Button, DatePicker, Input, notification, Select } from "antd";
import { Field, Formik } from "formik";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { alertError } from "../../utils/alert";
import TableWrap from "../common/Table";
import { connect } from "react-redux";
import { inventoryReportAPI } from "../../Graphs/Report/inventoryReportAPI";

class InventoryManagementReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
        data: [],
        cardColor: [{ label: "All", value: "all" }],
        selectedSalesValue: "",
        isLoading: false,
        searchText: "",
        selectColor: "",
        total: "",
        startIndex: 0,
        limitNumber: 10,
        pagination: {
          pageSize: 10,
          current: 1,
        },
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.getInventoryList = this.getInventoryList.bind(this);
    this.updatePagination = this.updatePagination.bind(this);
    
  }

async componentDidMount() {
    const colorArray = [
        {
            value: 'green',
            label: 'Green',
        },
        {
            value: 'yellow',
            label: 'Yellow',
        },
        {
            value: 'red',
            label: 'Red',
        },
    ]
    try {
      this.setState({ isLoading: true }); 
      await this.getInventoryList(0,10); 
      this.setState({
        cardColor: [...this.state.cardColor, ...colorArray],
     }); 
    } catch (error) {
      console.error("Error in componentDidMount:", error);
      alertError("REPORT", error); 
    } finally {
      this.setState({ isLoading: false }); 
    }
  }
  
    async getInventoryList(page, pageSize , filter = "all" , searchText='') {
        try {
        const { authToken } = this.props;
        const body = {
            search_text: searchText ? searchText :"",
            search_color: filter ? filter : "",
        };
    
        let inventoryListData = await inventoryReportAPI(
            authToken,
            body,
            page * pageSize, // Calculate skip value dynamically
            pageSize
        );
        this.setState({
            isLoading: true,
        }); 


        if (inventoryListData.status === 200) {
            this.setState({
            data: inventoryListData.data,
            total: inventoryListData.total,
            pagination: {
                ...this.state.pagination,
                current: page + 1, // Increment current page
            },
            });
        }
        } catch (e) {
        alertError("REPORT", e);
        } finally {
        this.setState({
            isLoading: false,
        });
        }
    }

    async updatePagination(pagination) {
        const { current, pageSize } = pagination;
        const skip = (current - 1) * pageSize;
    
        this.setState({
        isLoading: true,
        });
    
        await this.getInventoryList(skip, pageSize,this.state.selectColor,this.state.searchText);
    
        this.setState({
        isLoading: false,
        });
    }
  
    handleTableChange(pagination, filters) {
        this.setState(
        {
            isLoading: true,
            pagination,
        },
        () => this.getInventoryList(pagination.current - 1, pagination.pageSize,this.state.selectColor,this.state.searchText)
        );
    }
  

    handleChange = async (color) => {
        this.setState({ selectColor: color, isLoading: true });
    
        let page = 0;
        let limit = 10;
        await this.getInventoryList(page, limit, color,this.state.searchText);

        this.setState({ isLoading: false });
    };  

    handelSearchTextChange=async(event)=>{
        const searchText = event?.target?.value;
        this.setState({ searchText })
        let page = 0;
        let limit = 10;
        await this.getInventoryList(page, limit,this.state.selectColor,searchText);
    }
  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      fixed: true,
      width: "100px",
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
        title: "Name",
        key: "name",
        fixed: true,
        render: (text, item, index) => {
          return (
            <div>
              <div
                style={{
                  alignItems: "center",
                  display: "inline-block",
                  width: "100%",
                }}
              >
                <div flexDirection="column">
                  <strong>
                    {" "}
                    <div>{item.name ? item.name : ""}</div>
                  </strong>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        title: "Card Color",
        key: "card color",
        width: "150px",
        fixed: true,
        render: (text, item, index) => {
          return (
              <div>
                  {item.card_color === "green" && (
                      <span
                          style={{
                              backgroundColor: "green",
                              color: "white",
                              borderRadius: "15px",
                              padding: "5px 10px",
                          }}
                      >
                          Green
                      </span>
                  )}
                  {item.card_color === "yellow" && (
                      <span
                          style={{
                              backgroundColor: "orange",
                              color: "white",
                              borderRadius: "15px",
                              padding: "5px 10px",
                          }}
                      >
                          Yellow
                      </span>
                  )}
                  {item.card_color === "red" && (
                      <span
                          style={{
                              backgroundColor: "red",
                              color: "white",
                              borderRadius: "15px",
                              padding: "5px 10px",
                          }}
                      >
                          Red
                      </span>
                  )}
              </div>
           );
         }
      },
      {
        title: "Arabic Name",
        key: "arabic name",
        render: (text, item, index) => {
          return (
            <div>
              <div
                style={{
                  alignItems: "center",
                  display: "inline-block",
                  width: "100%",
                }}
              >
                <div flexDirection="column">
                  <strong>
                    {" "}
                    <div>{item.arabic_name ? item.arabic_name : "-"}</div>
                  </strong>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        title: "Lower Limit",
        key: "Lower Limit",
        width: "100px",
        render: (text, item, index) => {
          return <div>{item.lower_limit ? item.lower_limit : "-"}</div>;
        },
      },
      {
        title: "Upper Limit",
        key: "Upper Limit",
        width: "100px",
        render: (text, item, index) => {
          return <div>{item.upper_limit ? item.upper_limit : "-"}</div>;
        },
    },
    {
      title: "Category Name",
      key: "category name",
      render: (text, item, index) => {
        return <div>{item.category_name ? item.category_name : "-"}</div>;
      },
    },
    {
      title: "Available Count",
      key: "available count",
      width: "150px",
      render: (text, item, index) => {
        return <div>{item.available_count ? item.available_count : "-"}</div>;
      },
    },
    {
      title: "Purchased Perday",
      key: "purchased perday",
      width: "150px",
      render: (text, item, index) => {
        return <div>{item.each_day_buy ? item.each_day_buy : "-"}</div>;
      },
    },
    {
      title: "Available Days",
      key: "available days",
      width: "150px",
      render: (text, item, index) => {
        return <div>{item.available_days ? item.available_days : "-"}</div>;
      },
    },
  ];

  render() {
    return (
      <>
        <Formik
          initialValues={{
            selectColor: "",
          }}
        >
          {() => {
            return (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  width: "100%",
                  marginBottom: "20px",
                  // paddingLeft: "14px",
                  paddingRight: "55px",
                  justifyContent: "left",
                }}
              >
                <div style={{ cursor: "pointer" }}>
                      <Select
                        style={{ width: "200px" }}
                        defaultValue="All"
                        onChange={(color) => {
                          this.handleChange(color);
                        }}
                        options={this.state.cardColor}
                      ></Select>
                </div>
                <div style={{ cursor: "pointer", marginLeft: 10 }}>
                      <Input 
                       value={this.state.searchText}
                       placeholder="Search Card"
                       style={{width:300}}
                       onChange={this.handelSearchTextChange}
                      />
                </div>
              </div>
            );
          }}
        </Formik>

        <div style={{ "overflow-x": "auto" }}>
            <TableWrap
                data={this.state.data}
                columns={this.columns}
                isLoading={this.state.isLoading}
                total={this.state.total}
                pagination={this.state.pagination}
                handleTableChange={this.handleTableChange}
                scroll={{ x: 1400 }}
            />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
});

export default connect(mapStateToProps)(
  withRouter(InventoryManagementReport)
);
