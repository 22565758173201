import { Button, DatePicker, notification, Tooltip } from "antd";
import { Formik } from "formik";
import moment from "moment";
import React, { Component } from "react";
import {
  collectionReport,
  CollectionReport,
} from "../../Graphs/sales/CollectionReport";
import TableWrap from "../common/Table";
import { WalletOutlined } from "@ant-design/icons";
import CollectBalanceFromUser from "./CollectBalanceFromUser";
import { color } from "../../constant/comman";
import { alertError } from "../../utils/alert";
import { withRouter } from "react-router";
import { connect } from "react-redux";

class CollectAmountTransaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collectAmountList: [],
      startDate: "",
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
    };
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  getCollectAmountFromUserList = async (page, limit) => {
    try {
      const { authToken, history } = this.props;
      let obj = { date: this.state.startDate };
      let collectData = await collectionReport(authToken, obj, page, limit);

      this.setState({
        isLoading: true,
      });
      if (collectData.status == 200) {
        this.setState({
          collectAmountList: [
            ...collectData.data,
            { totalAmount: collectData.total_amount },
          ],
          totalCollect: collectData.length,
          isLoading: false,
        });
      } else if (collectData.status == 401) {
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw collectData && collectData.message
          ? collectData.message
          : "Error";
      }
    } catch (e) {
      alertError("SALES", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  async getLimitedData(pagination) {
    const page = pagination.current * pagination.pageSize - pagination.pageSize;
    const limit = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: page,
        limitNumber: limit,
      },
      async () => {
        this.getCollectAmountFromUserList(page, limit);
      }
    );
  }

  handleTableChange(pagination, filters) {
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedData(pagination)
    );
  }

  columns = [
    {
      title: "Sr. No.",
      width: 100,
      key: "serial_number",
      render: (text, item, index) => {
        return item.salesman ? (
          <div key={"sno" + index}>
            <span>{++index}</span>
          </div>
        ) : (
          <div key={"sno" + index}>
            <span style={{ fontWeight: 700 }}>Grand</span>
          </div>
        );
      },
    },
    {
      title: "Salesman Name",
      width: 200,
      key: "name",
      render: (text, item, index) => {
        return item.salesman ? (
          <div key={"name" + index}>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column" style={{ marginTop: "5px" }}>
                {item.salesman.first_name + " " + item.salesman.last_name}
              </div>
            </div>
          </div>
        ) : (
          <div key={"name" + index}>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div
                flexDirection="column"
                style={{ marginTop: "5px", fontWeight: 700 }}
              >
                Total Amount
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Collected Amount",
      width: 200,
      key: "collect_amount",
      render: (text, item, index) => {
        return item.total_collected_amount ? (
          <div key={"name" + index}>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column" style={{ marginTop: "5px" }}>
                {item.total_collected_amount}
              </div>
            </div>
          </div>
        ) : (
          <div key={"name" + index}>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div
                flexDirection="column"
                style={{ marginTop: "5px", fontWeight: 700 }}
              >
                {item.totalAmount}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Transaction Type",
      width: 200,
      key: "sales_name",
      render: (text, item, index) => {
        return (
          item.transaction_type && (
            <div key={"name" + index}>
              <div
                style={{
                  alignItems: "center",
                  display: "inline-block",
                  width: "100%",
                }}
              >
                <div flexDirection="column" style={{ marginTop: "5px" }}>
                  {item.transaction_type == "ADMIN_COLLECT_BALANCE"
                    ? "Admin Collect Balance"
                    : "-"}
                </div>
              </div>
            </div>
          )
        );
      },
    },
    {
      title: "Actions",
      width: 200,
      key: "sales_name",
      render: (text, item, index) => {
        return (
          item.transaction_type && (
            <div
              style={{
                display: "flex",
                gap: "10px",
                width: "100%",
                flexWrap: "wrap",
              }}
            >
              <Tooltip placement="bottom" title="Collect amount from user">
                <Button
                  shape="circle"
                  icon={<WalletOutlined />}
                  onClick={() => {
                    this.setState({
                      collectAmountDataFromUser: item,
                    });
                  }}
                />
              </Tooltip>
            </div>
          )
        );
      },
    },
  ];

  handleDetailsModalclose() {
    this.setState({
      collectAmountDataFromUser: null,
    });
  }

  render() {
    return (
      <>
        <Formik
          initialValues={{
            date: "",
          }}
          onSubmit={() => {
            let page = 0;
            let limit = 10;
            this.getCollectAmountFromUserList(page, limit);
          }}
        >
          {({ values, handleSubmit }) => {
            return (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  width: "100%",
                  marginBottom: "20px",
                  // paddingLeft: "14px",
                  paddingRight: "55px",
                  justifyContent: "left",
                }}
              >
                <div style={{ cursor: "pointer" }}>
                  <DatePicker
                    name="date"
                    onChange={(date, dateString) => {
                      this.setState({
                        startDate: dateString,
                      });
                    }}
                    style={{ width: "200px" }}
                    disabledDate={(current) => {
                      return moment().add(0, "days") <= current;
                    }}
                  />
                </div>

                <div style={{ cursor: "pointer", width: "100%" }}>
                  <Button
                    style={{background: "#5C6AC4", color: "#fff"}}
                    color={this.state.primaryColor}
                    onClick={handleSubmit}
                    disabled={this.state.startDate == ""}
                  >
                    Filter
                  </Button>
                </div>
              </div>
            );
          }}
        </Formik>

        {this.state.startDate && (
          <TableWrap
            data={this.state.collectAmountList}
            columns={this.columns}
            isLoading={this.state.isLoading}
            total={this.state.totalCollect}
            pagination={this.state.pagination}
            handleTableChange={this.handleTableChange}
            scroll={{ x: 800 }}
          />
        )}

        {this.state.collectAmountDataFromUser ? (
          <CollectBalanceFromUser
            visible={this.state.collectAmountDataFromUser}
            handleCancel={this.handleDetailsModalclose.bind(this)}
            handleOk={this.handleDetailsModalclose.bind(this)}
            onClose={this.handleDetailsModalclose.bind(this)}
            isLoading={this.state.isLoading}
            data={this.state.collectAmountDataFromUser}
            authToken={this.props.authToken}
            reload={this.props.reload}
            date={this.state.startDate}
          />
        ) : null}
      </>
    ); 
  }
} 

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
});

export default connect(mapStateToProps)(withRouter(CollectAmountTransaction));
