import { Button, notification, Tag, Tooltip } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { getTransactionAPI } from "../../Graphs/sales/getTransactionAPI";
import { alertError } from "../../utils/alert";
import ModalWrap from "./modal";
import TableWrap from "./Table";
import { dateFormat, timeFormat } from "../../utils/general";

class TransactionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionList: [],
      totaltransactionList: 0,
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
    };
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  componentDidMount() {
    let page = 0;
    let limit = 10;
    this.getTransactionDataList(page, limit);
  }

  handleTableChange() {}

  getTransactionDataList = async (page, limit) => {
    try {
      const { authToken, history } = this.props;

      let transactionData = await getTransactionAPI(
        authToken,
        page,
        limit,
        this.props.data.id
      );

      this.setState({
        isLoading: true,
      });
      if (transactionData.status == 200) {
        console.log("transaction data", transactionData);

        this.setState({
          transactionList: transactionData.data,
          totaltransactionList: transactionData.total,
          isLoading: false,
        });
      } else if (transactionData.status == 401) {
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw transactionData && transactionData.message
          ? transactionData.message
          : "Error";
      }
    } catch (e) {
      alertError("Transaction", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  columns = [
    {
      title: "Sr. No.",
      width: 100,
      key: "serial_number",
      render: (text, item, index) => {
        return (
          <div key={"sno" + index}>
            <span>{++index}</span>
          </div>
        );
      },
    },

    {
      title: "Amount (LYD)",
      key: "amount",
      width: 150,
      render: (text, item, index) => {
        return <div>{item.amount ? item.amount : "-"}</div>;
      },
    },
    {
      title: "Transaction Type",
      key: "transaction_type",
      width: 150,
      render: (text, item, index) => {
        return (
          <div>
            {item.transaction_type
              ? item.transaction_type == "ADMIN_RECHARGE_SALES"
                ? "Recharge"
                : "Collect"
              : "-"}
          </div>
        );
      },
    },
    {
      title: "Date",
      width: 200,
      key: "createdAt",
      render: (text, item, index) => {
        console.log("kjfhxgjkdf-----", item);
        return (
          <div key={"createdAt" + index}>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column" style={{ marginTop: "5px" }}>
                {item.createdAt !== null ? dateFormat(item.createdAt) : "-"} &nbsp;
                {item.createdAt !== null ? timeFormat(item.createdAt) : "-"}
              </div>
            </div>
          </div>
        );
      },
    },
  ];
  render() {
    return (
      <ModalWrap
        visible={this.props.visible}
        closable={this.props.onClose}
        title="Admin Sales Transaction"
        onOk={this.props.handleOk}
        onCancel={this.props.handleCancel}
        isLoading={this.props.isLoading}
        width="800px"
      >
        <TableWrap
          data={this.state.transactionList}
          columns={this.columns}
          isLoading={this.state.isLoading}
          total={this.props.totaltransactionList}
          pagination={this.state.pagination}
          handleTableChange={this.handleTableChange}
          scroll={{ x: 800 }}
        />
      </ModalWrap>
    );
  }
}
export default TransactionModal;
