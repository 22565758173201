import { callGetApiToServer } from "../callApi";

export async function getBalanceRequestList(
  authtoken,
  page,
  limit,
  filter,
  distributorId,
) {
  try {
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization": "Berear " + authtoken
    }
    let url = distributorId ? `?distributor_id=${distributorId}&filterBy=${filter}` : `?filterBy=${filter}`
    let endUrl = "v1/auth/list-balance-requests/" + page + "/" + limit + url

    let responseData = await callGetApiToServer(header, "GET", endUrl);
    return responseData;
  } catch (err) {
    throw err;
  }
}

