import { ENDPOINT } from '../../network/ENDPOINT';
import { deleteApiToServer } from '../callApi';

export async function deleteExpensesAPI(
    authtoken,
    id
) {
  try{
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization" : "Berear " + authtoken
    }
  
    let endUrl = `${ENDPOINT.delete_expenses}/${id}`;
    console.log("endUrl printed here", endUrl);
  
    let responseData = await deleteApiToServer(header, "DELETE", endUrl);
      
    return responseData;
  }catch(err) {
    throw err;
  }
}
