import * as React from "react";
import { notification, Button, Input } from "antd";
import { Form, Formik } from "formik";
import ModalWrap from "../common/modal";
import { addCoinToSales } from "../../Graphs/sales/AddCoinToSales";
import { alertError } from "../../utils/alert";

export class AddCoinInSales extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  validateForm = (values) => {
    const errors = {};
    if (!/^-?\d*\.?\d*$/.test(String(values.coin))) {
      errors.coin = "Please enter a valid price.";
    }

    return errors;
  };

  handleSubmit = async (value) => {
    try {
      let body = {
        salesman_id: this.props.data.id,
        amount: value.coin,
      };
      let response = await addCoinToSales(this.props.authToken, body);
      if (response.status == 200) {
        notification["success"]({
          message: response.message,
        });
        this.props.onClose();
        this.props.reload();
      } else {
        throw response;
      }
    } catch (error) {
      alertError("SALES", error.message);
    }
  };

  render() {
    return (
      <ModalWrap
        visible={this.props.visible}
        closable={this.props.onClose}
        title="Add balance in sales account"
        onOk={this.props.handleOk}
        onCancel={this.props.handleCancel}
        isLoading={this.props.isLoading}
      >
        <Formik
          initialValues={{
            coin: "",
          }}
          validate={this.validateForm}
          onSubmit={this.handleSubmit}
        >
          {({ values, errors, handleChange, handleBlur, handleSubmit }) => {
            return (
              <Form>
                {
                  <>
                    <div className="formik-field_wrap">
                      <div className="formik-field-wrap">
                        Add Balance
                        <Input
                          id="coin"
                          placeholder="Add Balance"
                          value={values.coin}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          // type="number"
                        />
                        {errors.coin ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.coin}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <Button
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  </>
                }
              </Form>
            );
          }}
        </Formik>
      </ModalWrap>
    );
  }
}
