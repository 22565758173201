import { callGetApiToServer, callApiToServer } from '../callApi';

export async function getDistributorList(
    authtoken,
    skipNumber,
    limitNumber
) {
    try {
        let header = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "authorization": "Berear " + authtoken
        }

        let endUrl = "v1/auth/list-distributor/" + skipNumber + "/" + limitNumber;

        let responseData = await callGetApiToServer(header, "GET", endUrl);

        return responseData;
    } catch (err) {
        throw err;
    }
}