export const numberRegx = /^[0-9]$/;

export const passwordRegx =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#?])[A-Za-z\d@$!%*?&#?]{8,}$/;

export const mobileRegx = /^[0-9]*$/;

export const emailRegx =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const priceRegx = /^-?\d*\.?\d*$/;

export const spaceRegx = /^(\S+$)/;

export const fnameRegx = /^[A-Z][a-z]*$/;
export const lnameRegx = /^[A-Z][a-z]*$/;