import React, { Fragment, Component, useState } from "react";
import {
  Table,
  Tooltip,
  notification,
  Spin,
  Select,
  Input,
  Button,
  Modal,
  DatePicker,
  Tabs,
} from "antd";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Breadcrumb from "../common/breadcrumb";
import { listUserBalanceReport } from "../../Graphs/Report/listUserBalanceReport";
import BalanceReportGraph from "./BalanceReportGraph";
import { alertError } from "../../utils/alert";
import { dateFormat } from "../../utils/general";
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

var moment = require("moment");

const TabPane = Tabs.TabPane;

class ListUserBalanceReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      userData: [],
      userDataTotal: 0,
    };
  }

  listCardListData = async (startDate, endDate) => {
    try {
      const {
        userData,
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      if (!startDate || !endDate) {
        alertError("REPORT", "Please select a range of date");

        return;
      }

      let userListCardData = await listUserBalanceReport(
        authToken,
        startDate,
        endDate
      );
      console.log("!!!!!!!!!!!!userListCardData", userListCardData);

      if (userListCardData.status == 200) {
        this.setState({
          isLoading: false,
          userData: userListCardData.data,
          userDataTotal: userListCardData.total,
        });
      } else if (userListCardData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw userListCardData && userListCardData.message
          ? userListCardData.message
          : "Error";
      }
    } catch (e) {
      alertError("REPORT", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };


  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      fixed: true,
      width: "8%",
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "User",
      key: "Username",
      width: "17%",
      render: (text, item, index) => {
        return (
          <div>
            {item.user && item.user.first_name + " " + item.user.last_name}
          </div>
        );
      },
    },
    {
      title: "Date",
      key: "Date",
      width: "15%",
      render: (text, item, index) => {
        return (
          <div>
            {item.createdAt !== null ? dateFormat(item.createdAt) : "-"}
          </div>
        );
      },
    },
    {
      title: "Amount",
      key: "Price",
      width: "10%",
      render: (text, item, index) => {
        return <div>{parseFloat(item.price).toFixed(2)}</div>;
      },
    },
    {
      title: "Remark",
      key: "Message",
      width: "40%",
      render: (text, item, index) => {
        let price = item.price && parseFloat(item.price).toFixed(2);

        const obj = {
          user: {
            "INSTANT REQUEST": `${
              item.user && item.user.first_name + " " + item.user.last_name
            } 
                                        recharge with amount LYD ${price} by instant balance.`,
            "BALANCE TRANSFER": `${
              item.by_user &&
              item.by_user.first_name + " " + item.by_user.last_name
            } transfer balance to ${
              item.user.first_name + " " + item.user.last_name
            }`,
          },
          admin: {
            BALANCE: `Admin recharge ${
              item.user.first_name + " " + item.user.last_name
            }
                                  with amount LYD ${price}.`,
            REQUEST: `Admin approved request ${
              item.user.first_name + " " + item.user.last_name
            } with amount LYD ${price}.`,
          },
          distributor: {
            BALANCE: `Distributor ${
              item.user.distributor &&
              item.user.distributor.first_name +
                " " +
                item.user.distributor.last_name
            }
                                    recharge ${
                                      item.user.first_name +
                                      " " +
                                      item.user.last_name
                                    } with amount LYD ${price}`,

            REQUEST: ` Distributor ${
              item.user.distributor &&
              item.user.distributor.first_name +
                " " +
                item.user.distributor.last_name
            }
                                    approved request ${
                                      item.user.first_name +
                                      " " +
                                      item.user.last_name
                                    } with amount LYD ${price} `,
          },

          sales: {
            BALANCE: `Salesman ${
              item.user.salesman &&
              item.user.salesman.first_name + " " + item.user.salesman.last_name
            }
                                    recharge ${
                                      item.user.first_name +
                                      " " +
                                      item.user.last_name
                                    } with amount LYD ${price}`,

            REQUEST: `Salesman ${
              item.user.salesman &&
              item.user.salesman.first_name + " " + item.user.salesman.last_name
            }
                                    approved request ${
                                      item.user.first_name +
                                      " " +
                                      item.user.last_name
                                    } with amount LYD ${price}`,
          },
        };

        return (
          <div>
            {item.type == "DEBIT"
              ? `${
                  item.user.first_name &&
                  item.user.first_name + " " + item.user.last_name
                } buy ${
                  item.transaction_by !== null ? item.transaction_by : "Admin"
                } card with amount LYD ${
                  item.price && parseFloat(item.price).toFixed(2)
                }.`
              : obj[item.transaction_by ? item.transaction_by : "admin"][
                  item.createdBy ? item.createdBy : "BALANCE"
                ]}
          </div>
        );
      },
    },
  ];

  financialYear = (value) => {
    var currentMonth = new Date().getMonth();
    let year = moment().year();
    if (currentMonth < 3) {
      year -= 1;
    }

    const start = moment(`1/4/${year}`, "DD/MM/YYYY");
    const end = value ? moment() : moment(`31/03/${year + 1}`, "DD/MM/YYYY");
    return [start, end];
  };

  previousFinancialYear = () => {
    var currentMonth = new Date().getMonth();
    let year = moment().year();
    const start = moment(`1/4/${year - 1}`, "DD/MM/YYYY");
    const end = moment(`31/3/${year}`, "DD/MM/YYYY");
    return [start, end];
  };

  clearTableData = () => {
    this.setState({
      userData: [],
      userDataTotal: 0,
    });
  };

  onChangeDate = (date) => {
    if (!date || !date.length) {
      this.clearTableData(); 
      this.setState({
        startDate: null,
        endDate: null,
      });
    } else {
      this.setState({
        startDate: date[0].format("YYYY-MM-DD HH:mm:ss"),
        endDate: date[1].format("YYYY-MM-DD HH:mm:ss"),
      });
    }
  };

  onFilter = async () => {
    try {
      const { startDate, endDate } = this.state;
      await this.listCardListData(startDate, endDate);
    } catch (e) {
      console.log("!!!!!!!error printed here onFilter", e);
    }
  };

  generateCsv = async () => {
    try {
      const { userData , startDate , endDate } = this.state;
      if (!(userData && userData.length > 0)) {
        return;
      }

      this.setState({
        isLoading: true,
      });

      let csvArray = [];

      csvArray.push(["Sr.No.", "User", "Date", "Amount", "Remark"]);

      userData.map((data, index) => {
        csvArray.push([
          index + 1,
          data.coin ? data.coin.name : "NA",
          dateFormat(data.createdAt),
          data.price,
        ]);
      });

      const rows = csvArray;
      let csvName = `user_balance_report_${dateFormat(startDate)}-${dateFormat(endDate)}.csv`;
      let csvContent = "data:text/csv;charset=utf-8,";

      rows.forEach(function (rowArray) {
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
      });

      var encodedUri = encodeURI(csvContent);
      // // // // window.open(encodedUri);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", csvName);
      document.body.appendChild(link); // Required for FF

      link.click();
    } catch (e) {
      console.log("error in generateCsv function", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };


  render() {
    const { isLoading, searchText, userData, userDataTotal } = this.state;

    return (
      <Fragment>
        <Tabs destroyInactiveTabPane="true">
          <TabPane tab="Card Table Data" key="1">
            <div
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                display: "inline-block",
              }}
            >
              <div style={{ float: "left", width: "50%" }}>
                <RangePicker
                  format="DD-MM-YYYY"
                  onChange={this.onChangeDate}
                  ranges={{
                    Today: [
                      moment().startOf("day"),
                      moment().add(1, "days").endOf("day"),
                    ],
                    Yesterday: [
                      moment().subtract(1, "days").startOf("day"),
                      moment().endOf("day"),
                      // moment()
                      //   .subtract(1, "days")
                      //   .endOf("day")
                    ],
                    "This Week": [
                      moment().startOf("week"),
                      moment().endOf("week"),
                    ],
                    "This Financial Year": this.financialYear(0),
                    "Previous Financial Year": this.previousFinancialYear(),
                  }}
                  disabledDate={(current) => {
                    return moment().add(0, "days") <= current;
                  }}
                />
              </div>
              <div style={{ float: "left" }}>
                <Button
                  style={{
                    marginTop: "0px",
                    background: "#5C6AC4",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                  onClick={this.onFilter}
                >
                  Filter
                </Button>

                <Button
                  style={{
                    marginTop: "0px",
                    background: "#5C6AC4",
                    color: "#fff",
                    cursor: "pointer",
                    marginLeft: "15px",
                  }}
                  onClick={() => {
                    this.generateCsv();
                  }}
                >
                  Generate CSV
                </Button>
              </div>
            </div>
            <div
              style={{
                "overflow-x": "auto",
                width: "100%",
                display: "inline-block",
              }}
            >
              <Table
                loading={this.state.isLoading}
                rowKey={(record) => record._id}
                columns={this.columns}
                size={"small"}
                scroll={{ x: 900 }}
                // dataSource={this.state.data}
                dataSource={userData}
                pagination={false}
              />
            </div>
          </TabPane>
          <TabPane tab="Card Graph Data" key="2">
            <div>
              <BalanceReportGraph />
            </div>
          </TabPane>
        </Tabs>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
  };
}; 

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ListUserBalanceReport));

